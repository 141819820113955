/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require("./bootstrap");

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require("./components/admin/analytics/AnalyticsMain");
require("./components/admin/articles/ArticlesMain");
require("./components/admin/articles/Editor");
require("./components/admin/clients/ClientsMain");
require("./components/admin/users/UsersMain");
require("./components/articles/ArticlesMain");
require("./components/auth/Login");
require("./components/Catalog");
require("./components/dashboard/species/MainSpecies");
require("./components/dashboard/Dashboard");
require("./components/home/Home");
require("./components/mail/MailMain");
require("./components/products/ProductsMain");
