import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import { useSpring, animated } from "react-spring";

function ItemContent(props) {
    const { t, i18n } = useTranslation();
    const { item, itemType, selectItem } = props;

    const anim = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: { duration: 1000 },
        reset: true,
    });

    const title = item.forEverything
        ? "Disponible para toda la línea de productos PacLife"
        : itemType == "productos"
        ? "Productos disponibles para esta especie"
        : "Producto disponible para estas especies";

    return item != undefined ? (
        <animated.div style={anim} className="h-100 d-flex flex-column">
            <h3 className="one mb-5">{t(item.name)}</h3>

            <div className="six pb-4">
                <p>{item.description}</p>

                <ul>
                    {item.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                    ))}
                </ul>
            </div>

            <div className="mt-auto">
                <h4 className="four mb-3">{t(title)}</h4>

                {!item.forEverything && (
                    <div id="relatedContent">
                        {item.related.map((related, i) => (
                            <span key={i}>
                                {"| "}
                                <a onClick={() => selectItem(related.name)}>
                                    {t(related.name)}
                                </a>{" "}
                            </span>
                        ))}
                    </div>
                )}
            </div>
        </animated.div>
    ) : (
        <div className="d-flex justify-content-center p-5">
            <Spinner color="light" />
        </div>
    );
}

export default ItemContent;
