import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import ProductStages from "./ProductStages";
import RelatedItems from "./RelatedItems";

function ItemContent(props) {
    const { t, i18n } = useTranslation();
    const { item, itemType } = props;

    return item != undefined ? (
        <div className="h-100 d-flex flex-column">
            <div className="six pb-4">
                <p className="mb-4 font-weight-bold">{item.description}</p>

                <ul>
                    {item.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                    ))}
                </ul>
            </div>

            <div className="mt-auto">
                {itemType === "product" ? (
                    <ProductStages stages={item.stages} />
                ) : (
                    <RelatedItems item={item} />
                )}
            </div>
        </div>
    ) : (
        <Loading />
    );
}

export default ItemContent;
