import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import CurArticle from "./CurArticle";
import ArticleList from "./ArticleList";
import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather";
import Loading from "../Loading";
import TagBox from "./TagBox";
import ArticleCard from "./ArticleCard";
import Lang from "../home/Lang";
import Header from "../home/header/Header";
import PhoneHeader from "../home/header/PhoneHeader";

function ArticlesMain(props) {
    const { curArticle } = props;
    const { t, i18n } = useTranslation();

    const [articles, setArticles] = useState([]);
    const [links, setLinks] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [curTag, setCurTag] = useState(null);

    useEffect(() => {
        const query = curTag ? `&tag=${curTag}` : "";
        setIsLoading(true);

        axios
            .get("/api/articles?pag=6" + query)
            .then((res) => {
                setArticles(res.data.data);
                setLinks(res.data.links);
                setIsLoading(false);
            })
            .catch((e) => console.error(e));
    }, [curTag]);

    function paginate(direction) {
        setIsLoading(true);

        axios
            .get(links[direction])
            .then((res) => {
                setArticles(res.data.data);
                setLinks(res.data.links);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }

    return (
        <>
            <header>
                <Header />
                <PhoneHeader />
            </header>

            <main className="container py-5">
                <h2 className="page-title">{t("Noticias")}</h2>

                <CurArticle article={curArticle} />

                <div className="other-articles">
                    <h3>
                        {t("Más noticias")} <ChevronDown className="ml-3" />
                    </h3>

                    {curArticle.tags.length > 0 && (
                        <TagBox
                            curArticle={curArticle}
                            curTag={curTag}
                            setCurTag={setCurTag}
                        />
                    )}

                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="article-list">
                            <button
                                onClick={() => paginate("prev")}
                                style={{ left: -60 }}
                            >
                                {links.prev && (
                                    <ChevronLeft color="#3b6895" size={60} />
                                )}
                            </button>

                            <nav className="row row-cols-1 row-cols-md-2 row-cols-xl-3">
                                {articles.map((article) => (
                                    <ArticleCard
                                        key={article.id}
                                        article={article}
                                        isActive={curArticle.id == article.id}
                                    />
                                ))}
                            </nav>

                            <button
                                onClick={() => paginate("next")}
                                style={{ right: -60 }}
                            >
                                {links.next && (
                                    <ChevronRight color="#3b6895" size={60} />
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
}

const root = document.getElementById("blog");
if (root) {
    const json = root.getAttribute("article");
    const curArticle = JSON.parse(json);
    ReactDOM.render(<ArticlesMain curArticle={curArticle} />, root);
}
