import React, { useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import { Edit, Eye, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import ReportPreview from "./ReportPreview";
import ReportMobile from "./ReportMobile";
import ConfirmModal from "../../ConfirmModal";

function ReportList(props) {
    const {
        clientSelected,
        isClient,
        reports,
        setIsLoading,
        update,
        editReport,
        toggle,
    } = props;

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [curReport, setCurReport] = useState({});
    const [documentLinks, setDocumentLinks] = useState([]);
    const [loadingLinks, setLoadingLinks] = useState(0);
    const [showDocument, setShowDocument] = useState(false);

    function parseTS(ts) {
        const date = new Date(ts);
        return date.toLocaleDateString();
    }

    function toggleDeleteModal(id = 0) {
        if (id === 0) {
            setCurReport({});
        } else {
            setCurReport(reports.find((report) => report.id === id));
        }

        setDeleteModalOpen(!deleteModalOpen);
    }

    function deleteReport() {
        setDeleteLoading(true);

        axios
            .delete(`/api/reports/${curReport.id}`)
            .then((res) => {
                console.log(res);
                toggleDeleteModal();
                setIsLoading(true);
                update();
            })
            .catch((err) => {
                console.error(err);
                alert(err.response.data);
                toggleDeleteModal();
            });
    }

    async function getLinks(id) {
        setLoadingLinks(id);

        await axios(`/api/reports/${id}/links`)
            .then((res) => {
                setDocumentLinks(res.data);
                setShowDocument(true);
            })
            .catch((err) => {
                console.error(err);
                alert(err.response.data);
            });

        setLoadingLinks(0);
    }

    return (
        <>
            <ConfirmModal
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                action={deleteReport}
                label="Eliminar"
                loading={deleteLoading}
            >
                <p>¿Realmente desea eliminar este reporte?</p>

                {curReport && (
                    <ul className="text-left">
                        <li>Cliente: {curReport.client?.name}</li>
                        <li>Planta: {curReport.plant?.name}</li>
                        <li>Especie: {curReport.species?.name}</li>
                        <li>Temporada: {curReport.season?.name}</li>
                        <li>Fecha: {parseTS(curReport.date)}</li>
                    </ul>
                )}
            </ConfirmModal>

            <Table id="reports-desktop" responsive bordered>
                <thead>
                    <tr>
                        {!clientSelected && <th>Cliente</th>}

                        <th>Especie</th>
                        <th>Planta</th>
                        <th>Temporada</th>
                        <th>Fecha</th>
                        <th>Subido por</th>

                        {!isClient && (
                            <>
                                <th>Última edición</th>
                                <th>Estado</th>
                            </>
                        )}

                        <th>{isClient ? "Ver" : "Acciones"}</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report) => (
                        <tr
                            key={report.id}
                            className={
                                report.draft ? "bg-light text-muted" : ""
                            }
                        >
                            {!clientSelected && <td>{report.client}</td>}

                            <td className="text-capitalize">
                                {report.species}
                            </td>
                            <td>{report.plant}</td>
                            <td>{report.season}</td>
                            <td>{parseTS(report.date)}</td>

                            {isClient ? (
                                <td>{report.editor}</td>
                            ) : (
                                <>
                                    <td>{report.uploader}</td>
                                    <td>{report.editor}</td>
                                    <td>
                                        {report.draft ? "Borrador" : "Enviado"}
                                    </td>
                                </>
                            )}

                            <td>
                                <div className="d-flex justify-content-around">
                                    <div>
                                        {loadingLinks === report.id ? (
                                            <Spinner
                                                color="success"
                                                size="sm"
                                            />
                                        ) : (
                                            <Eye className="pointer"
                                                color="#009955"
                                                onClick={() =>
                                                    getLinks(report.id)
                                                }
                                                data-tip
                                                data-for="ttDescargarReporte" />
                                        )}

                                        <ReactTooltip
                                            id="ttDescargarReporte"
                                            effect="solid"
                                        >
                                            <span>Ver Reporte</span>
                                        </ReactTooltip>
                                    </div>

                                    {!isClient && (
                                        <div>
                                            <Edit
                                                className="pointer"
                                                color="#ffc107"
                                                onClick={() =>
                                                    editReport(report.id)
                                                }
                                                data-tip
                                                data-for="ttEditarReporte"
                                            />

                                            <ReactTooltip
                                                id="ttEditarReporte"
                                                effect="solid"
                                            >
                                                <span>Editar Reporte</span>
                                            </ReactTooltip>
                                        </div>
                                    )}

                                    {!isClient &&
                                        <div>
                                            <XCircle
                                                className="pointer"
                                                color="#dc3545"
                                                onClick={() => toggleDeleteModal(report.id)}
                                                data-tip
                                                data-for="ttEliminarReporte"
                                            />

                                            <ReactTooltip
                                                id="ttEliminarReporte"
                                                effect="solid"
                                            >
                                                <span>
                                                    Eliminar Reporte
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div id="reports-mobile">
                {!isClient && (
                    <Button
                        id="new-report-toast"
                        outline
                        color="primary"
                        onClick={toggle}
                        className="btn-sqr mb-3 w-100"
                    >
                        Nuevo Reporte
                    </Button>
                )}

                {reports.map((report, index) => (
                    <ReportMobile
                        key={index}
                        index={index}
                        report={report}
                        isClient={isClient}
                        parseTS={parseTS}
                        getLinks={getLinks}
                        editReport={editReport}
                        deleteReport={toggleDeleteModal}
                    />
                ))}
            </div>

            <ReportPreview
                isOpen={showDocument}
                toggle={() => setShowDocument(!showDocument)}
                links={documentLinks}
            />
        </>
    );
}

export default ReportList;
