import React from "react";
import LinkSlider from "./LinkSlider";
import VideoBg from "./VideoBg";

function Slider() {
    return (
        <VideoBg>
            <nav className="container videoLinks">
                <LinkSlider
                    titulo={"Inocuidad<br />y protección"}
                    link={"Tecnología"}
                    href="#tecnologia"
                />
                <LinkSlider
                    titulo={"Soluciones<br />personalizadas"}
                    link={"Productos"}
                    href="#productos"
                />
                <LinkSlider
                    titulo={"Presencia<br />global"}
                    link={"Dónde estamos"}
                    href="#nosotros"
                />
            </nav>
        </VideoBg>
    );
}

export default Slider;
