import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CurNews from "./CurNews";
import NewsList from "./NewsList";
import { useController } from "react-scroll-parallax";
import { ChevronLeft, ChevronRight } from "react-feather";
import Loading from "../../Loading";

function News(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    const [news, setNews] = useState([]);
    const [curNews, setCurNews] = useState({});
    const [links, setLinks] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [articleLoading, setArticleLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/api/articles?pag=2")
            .then((res) => {
                setNews(res.data.data);
                setLinks(res.data.links);
                if (!props.article) {
                    setCurNews(res.data.data[0]);
                    setArticleLoading(false);
                }
                setIsLoading(false);
            })
            .catch((e) => console.error(e));
    }, []);

    const { parallaxController } = useController();
    useEffect(() => parallaxController.update());

    function paginate(direction) {
        setIsLoading(true);

        axios
            .get(links[direction])
            .then((res) => {
                setNews(res.data.data);
                setLinks(res.data.links);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Noticias")}</h2>

            {articleLoading ? <Loading /> : <CurNews article={curNews} />}

            <div className="borde-arriba-abajo px-4 mb-5">
                <h3 className="two text-left">{t("Otras noticias")}</h3>

                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="row mb-4">
                        <div className="col-lg-1 d-flex justify-content-center align-items-center">
                            {links.prev ? (
                                <ChevronLeft
                                    color="#3b6895"
                                    size={60}
                                    className="pointer"
                                    onClick={() => paginate("prev")}
                                />
                            ) : (
                                ""
                            )}
                        </div>

                        <NewsList news={news} curNews={curNews} />

                        <div className="col-lg-1 d-flex justify-content-center align-items-center">
                            {links.next ? (
                                <ChevronRight
                                    color="#3b6895"
                                    size={60}
                                    className="pointer"
                                    onClick={() => paginate("next")}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default News;
