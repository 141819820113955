import React, { useState } from "react";
import { Button } from "reactstrap";

function ClientList(props) {
    const { curClient, selectClient, clients } = props;

    const [filtered, setFiltered] = useState(clients);
    const [key, setKey] = useState("");

    function handleChange(newKey) {
        setKey(newKey);
        search(newKey);
    }

    function search(key) {
        const arr = clients.filter((client) =>
            client.name.toUpperCase().includes(key.toUpperCase())
        );

        setFiltered(arr);
    }

    function isCurClient(id) {
        return id == curClient.id;
    }

    return (
        <>
            <div>
                <input
                    type="search"
                    className="form-control"
                    value={key}
                    placeholder="Buscar..."
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>

            <div className="my-4 vh-100 overflow-auto">
                <div>
                    <Button
                        type="button"
                        color="link"
                        className={
                            "text-white" +
                            (isCurClient(0) ? " font-weight-bold" : "")
                        }
                        onClick={() => selectClient({ id: 0 })}
                    >
                        Últimos reportes
                    </Button>

                    <div className="bar" />
                </div>

                {filtered.map((client) => (
                    <div key={client.id}>
                        <Button
                            type="button"
                            color="link"
                            className={
                                "text-white" +
                                (isCurClient(client.id)
                                    ? " font-weight-bold"
                                    : "")
                            }
                            onClick={() => selectClient(client)}
                        >
                            {isCurClient(client.id) ? "▸ " : ""}
                            {client.name}
                        </Button>

                        <div className="bar" />
                    </div>
                ))}
            </div>
        </>
    );
}

export default ClientList;
