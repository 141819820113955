import React from "react";
import { useTranslation } from "react-i18next";

function Technology(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Tecnología")}</h2>
            <figure id="techInfo">
                <div id="grafica-tec-desktop">
                    <div id="tec-a">
                        <div id="tec-1">
                            <p>{t("Material 100% reciclable")}</p>
                            <p>
                                {t(
                                    "Tecnología patentada n° 50.344 con Nano-Tecnología (Zeolita)"
                                )}
                            </p>
                            <p>
                                {t(
                                    "Alta permeabilidad ajustada según cada especie hortofrutícola"
                                )}
                            </p>
                        </div>
                        <div id="tec-2">
                            <p>
                                {t(
                                    "Membrana selectiva Thermo Active de rápida reacción frente a quiebres de temperatura"
                                )}
                            </p>
                            <p>
                                {t(
                                    "Aditivo antifog, difusor del exceso de vapor de agua (condensación)"
                                )}
                            </p>
                        </div>
                    </div>
                    <div id="tec-b">
                        <div id="tec-3">
                            <p>
                                {t(
                                    "Materias primas vírgenes sustentables: reducibles, reciclables y reutilizables"
                                )}
                            </p>
                        </div>
                        <div id="tec-o">
                            <img src="/images/technology/seal.svg" alt="" />

                            <svg viewBox="0 0 100 100">
                                <path
                                    id="arco-sup"
                                    d="M 11 50 A 38.5 38.5 0 1 1 89 50"
                                    fill="none"
                                />

                                <text id="text-sup" textLength="122">
                                    <textPath
                                        xlinkHref="#arco-sup"
                                        dominantBaseline="middle"
                                        textLength="122"
                                    >
                                        {t("Nanotecnología patentada")}
                                    </textPath>
                                </text>

                                <path
                                    id="arco-inf"
                                    d="M 10 62 A 42 42 0 0 0 90 62"
                                    fill="none"
                                />

                                <text id="text-inf" textLength="105">
                                    <textPath
                                        xlinkHref="#arco-inf"
                                        dominantBaseline="middle"
                                        textLength="105"
                                    >
                                        {t("Economía circular")}
                                    </textPath>
                                </text>

                                <circle cx="10" cy="56" r="1.7" />
                                <circle cx="90" cy="56" r="1.7" />

                                <text
                                    id="text-mid-sup"
                                    x="50"
                                    y="50"
                                    textAnchor="middle"
                                    textLength="45"
                                    lengthAdjust="spacingAndGlyphs"
                                >
                                    50.344
                                </text>

                                <text
                                    className="text-mid-inf"
                                    x="49"
                                    y="64.5"
                                    textAnchor="middle"
                                // textLength="27"
                                // lengthAdjust="spacingAndGlyphs"
                                >
                                    {t("Diseñado")}
                                </text>
                                <text
                                    className="text-mid-inf"
                                    x="49"
                                    y="72.5"
                                    textAnchor="middle"
                                // textLength="27"
                                // lengthAdjust="spacingAndGlyphs"
                                >
                                    {t("para una")}
                                </text>
                            </svg>
                        </div>

                        <div id="tec-4">
                            <p>{t("Tecnología SafeCare")}</p>
                            <p>{t("Inocuidad y protección")}</p>
                            <p>
                                <span />
                                <span>{t("Garantizada")}</span>
                                <span />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="viewPhone">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="supportSub mb-5">
                                {t(
                                    "Alta y ajustada permeabilidad según el tipo de fruta"
                                )}
                            </div>
                            <div className="mb-5">
                                <p className="text-justify">
                                    {t(
                                        "Liner confeccionado en mono lámina de p.e.b.d. (polietileno de baja densidad) y aditivos o nano componentes orgánicos que permiten el "
                                    )}
                                    <strong>
                                        {t(
                                            "óptimo ajuste y control a cambios de temperatura o diferencias de éstas entre producto empacado, zona de manejo y/o guarda en cámara de mantención, "
                                        )}
                                    </strong>
                                    {t(
                                        "evacuando gradualmente la energía o vapor de agua de forma controlada sin afectar al fruto y controlando el efecto de condensación."
                                    )}
                                </p>
                            </div>
                        </div>

                        <div id="grafica-tec-mobile">
                            <div>
                                <svg viewBox="0 0 100 60">
                                    <path
                                        id="arco-mob"
                                        d="M 9.2 27 A 50.04 50.04 0 0 0 90.8 27"
                                        fill="none"
                                    />

                                    <text textLength="95.41">
                                        <textPath
                                            xlinkHref="#arco-mob"
                                            dominantBaseline="hanging"
                                        >
                                            {t(
                                                "Materias primas vírgenes sustentables: reducibles, reciclables y reutilizables"
                                            )}
                                        </textPath>
                                    </text>
                                </svg>
                            </div>
                            <div>
                                <p>
                                    {t(
                                        "Membrana selectiva Thermo Active de rápida reacción frente a quiebres de temperatura"
                                    )}
                                </p>
                                <p>
                                    {t(
                                        "Aditivo antifog, difusor del exceso de vapor de agua (condensación)"
                                    )}
                                </p>
                                <p>
                                    {t(
                                        "Tecnología patentada n° 50.344 con Nano-Tecnología (Zeolita)"
                                    )}
                                </p>
                                <p>{t("Material 100% reciclable")}</p>
                                <p>{t("Diseño a medida")}</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <p className="text-justify">
                                {t(
                                    "Nuestro sistema de captura de vapor de agua es por medio de una neblina (micro gota), la cual puede identificarse en la parte superior del envase, que junto a nuestro sistema de higroscopia permiten su eliminación a través del film, sin la necesidad de utilización de ceras que movilizan las gotas al fondo de la caja."
                                )}
                            </p>
                            <p>
                                {t(
                                    "Nuestros envases durante el proceso de envío de fruta se estabiliza generando la eliminación de la neblina con óptimos arribos, envases secos, permitiéndonos a la fecha contar con una carta de presentación intachable frente a nuestros clientes."
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </figure>
        </section>
    );
}

export default Technology;
