import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Spinner } from "reactstrap";
import FormModal from "../../FormModal";
import NewUser from "./NewUser";
import UserList from "./UserList";

function UsersMain() {
    const [status, setStatus] = useState(0);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        axios
            .get("/api/users")
            .then((res) => {
                setUsers(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }, [status]);

    function toggle() {
        setModal(!modal);
    }

    function update() {
        // console.log("updating...");
        setStatus(status + 1);
    }

    return (
        <div className="container pb-5">
            <div className="row justify-content-center mb-5">
                <div className="col-md-8">
                    <h1 className="d-inline nine text-dark text-uppercase">
                        Gestor de usuarios
                    </h1>

                    <FormModal
                        title="Nuevo Usuario"
                        modal={modal}
                        toggle={toggle}
                        submit="Guardar"
                        isLoading={sending}
                        form="newUser"
                        button
                    >
                        <NewUser
                            setSending={setSending}
                            setIsLoading={setIsLoading}
                            toggle={toggle}
                            update={update}
                        />
                    </FormModal>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    {isLoading ? (
                        <div className="center-xy p-5">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <UserList
                            users={users}
                            update={update}
                            setIsLoading={setIsLoading}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const root = document.getElementById("users");
if (root) {
    ReactDOM.render(<UsersMain />, root);
}
