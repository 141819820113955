import axios from "axios";
import { useState } from "react";
import { Save } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Button, Spinner } from "reactstrap";

function ContactMoveForm(props) {
    const { pivot, otherPlants, update } = props;

    const [plant, setPlant] = useState(0);
    const [error, setError] = useState("");
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [loading, setLoading] = useState(false);

    function validate(value) {
        if (value > 0) {
            setError("");
            return true;
        } else {
            setError("Seleccione una planta, por favor");
            return false;
        }
    }

    function handleChange(e) {
        const value = e.target.value;

        if (submitAttempted) {
            validate(value);
        }

        setPlant(value);
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitAttempted(true);
        setLoading(true);

        if (validate(plant)) {
            axios.post(
                `/api/contacts/${pivot}/move`,
                { plant }
            ).then(() => {
                update();
            }).catch((err) => {
                setError(err.response.data.message);
                setLoading(false);
            });
        }
    }

    return (
        <form className="d-flex" onSubmit={handleSubmit}>
            <div>
                <select
                    name="plant"
                    value={plant}
                    required
                    className="d-block"
                    onChange={handleChange}
                >
                    <option value={0} disabled>
                        Seleccione una planta
                    </option>

                    {otherPlants.map((plant) => (
                        <option
                            key={plant.id}
                            value={plant.id}
                        >
                            {plant.name}
                        </option>
                    ))}
                </select>

                <small className="text-danger">{error}</small>
            </div>

            {loading ? (
                <span className="px-2">
                    <Spinner color="success" size="sm" />
                </span>
            ) : (
                <span>
                    <Button
                        color="link"
                        type="sumbit"
                        className="px-1 py-0"
                        data-tip
                        data-for="ttMoveContact"
                    >
                        <Save color="#009955" size="24" />
                    </Button>

                    <ReactTooltip id="ttMoveContact" effect="solid">
                        Confirmar
                    </ReactTooltip>
                </span>
            )}
        </form>
    );
}

export default ContactMoveForm;
