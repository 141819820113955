import React from "react";
import { Download } from "react-feather";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import DocumentPreview from "./DocumentPreview";

function ReportPreview(props) {
    const { isOpen, toggle, links } = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="preview-modal">
            <ModalHeader className="bg-primary text-white" toggle={toggle}>
                Ver Reporte
            </ModalHeader>

            <ModalBody>
                <ListGroup flush>
                    {links.map((link, i) => (
                        <ListGroupItem key={i} className="px-0">
                            <p className="three d-flex justify-content-between">
                                {link.name}

                                <a href={link.url}>
                                    <Button color="success" className="btn-sqr text-white">
                                        <Download />

                                        Descargar
                                    </Button>
                                </a>
                            </p>

                            <DocumentPreview url={link.url} />
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </ModalBody>

            <ModalFooter className="d-flex justify-content-end align-items-center bg-light">
                <Button color="secondary" className="btn-sqr" onClick={toggle}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ReportPreview;
