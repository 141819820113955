import React, { useState, useEffect } from "react";
import * as yup from "yup";
import axios from "axios";
import { Button, Spinner } from "reactstrap";
import { PlusCircle, Save, SkipBack } from "react-feather";
import ReactTooltip from "react-tooltip";

function ClientContactForm(props) {
    const {
        setIsLoading,
        update,
        contact,
        clientId,
        plantId,
        species,
        setServerError,
        setEditContact,
    } = props;

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [sending, setSending] = useState(false);

    const fields = { species: 0, email: "" };
    const defaults = contact
        ? { species: contact.species_id, email: contact.email }
        : fields;

    useEffect(() => {
        // console.log("init");
        setValues(defaults);
        setErrors(fields);
    }, []);

    const schema = yup.object().shape({
        species: yup.number().required().positive().integer(),
        email: yup
            .string()
            .required("por favor ingrese un correo")
            .email("formato incorrecto")
            .max(64),
    });

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        const data = { ...values, client: clientId, plant: plantId };

        if (contact) {
            data.pivot = contact.pivot;
        }

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios({
                    method: contact ? "put" : "post",
                    url:
                        "/api" +
                        (contact ? "" : `/clients/${clientId}`) +
                        "/contacts" +
                        (contact ? `/${contact.id}` : ""),
                    data: data,
                })
                    .then((res) => {
                        console.log(res);
                        setSending(false);
                        update();
                        setIsLoading(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        setServerError(err.response.data.message);
                        setSending(false);
                    })
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setErrors({ ...errors, ...errBuff });
    }

    return (
        <tr>
            <td>
                <select
                    className={
                        "border w-100" +
                        (errors.species ? " border-danger" : "")
                    }
                    name="species"
                    value={values.species}
                    onChange={(e) => changeValue("species", e.target.value)}
                >
                    <option value={0} disabled>
                        Seleccione una especie
                    </option>

                    {species.map((species, i) => (
                        <option key={i} value={species.id}>
                            {species.name}
                        </option>
                    ))}
                </select>

                <small className="text-danger">{errors.name}</small>
            </td>

            <td>
                <input
                    className={
                        "border w-100" + (errors.email ? " border-danger" : "")
                    }
                    type="email"
                    name="email"
                    placeholder="Nuevo contacto"
                    value={values.email || ""}
                    onChange={(e) => changeValue("email", e.target.value)}
                />

                <small className="text-danger">{errors.name}</small>
            </td>

            <td>
                {sending ? (
                    <span className="px-2">
                        <Spinner color="success" size="sm" />
                    </span>
                ) : contact ? (
                    <>
                        <Button
                            color="link"
                            onClick={handleSubmit}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttUpdateContact"
                        >
                            <Save color="#009955" size="24" />
                        </Button>

                        <ReactTooltip id="ttUpdateContact" effect="solid">
                            Guardar cambios
                        </ReactTooltip>

                        <Button
                            color="link"
                            onClick={() => setEditContact(0)}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttCancel"
                        >
                            <SkipBack color="gray" size="24" />
                        </Button>

                        <ReactTooltip id="ttCancel" effect="solid">
                            Cancelar edición
                        </ReactTooltip>
                    </>
                ) : (
                    <>
                        <Button
                            color="link"
                            onClick={handleSubmit}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttCreateContact"
                        >
                            <PlusCircle color="#009955" size="24" />
                        </Button>

                        <ReactTooltip id="ttCreateContact" effect="solid">
                            Guardar contacto
                        </ReactTooltip>
                    </>
                )}
            </td>
        </tr>
    );
}

export default ClientContactForm;
