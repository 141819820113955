import React from "react";
import { Button } from "reactstrap";

function ClientSpeciesList(props) {
    const { user, curSpecies, setCurSpecies } = props;

    function isCurSpecies(id) {
        return id == curSpecies.id;
    }

    return (
        <div>
            {user.species.map((species) => (
                <div key={species.id}>
                    <Button
                        type="button"
                        color="link"
                        className={
                            "text-white" +
                            (isCurSpecies(species.id)
                                ? " font-weight-bold"
                                : "")
                        }
                        onClick={() => setCurSpecies(species)}
                    >
                        {isCurSpecies(species.id) ? "▸ " : ""}
                        {species.name}
                    </Button>

                    <div className="bar" />
                </div>
            ))}
        </div>
    );
}

export default ClientSpeciesList;
