import React, { useState, useEffect } from "react";
import * as yup from "yup";
import axios from "axios";
import { Button, Spinner } from "reactstrap";
import { PlusCircle, Save, SkipBack } from "react-feather";
import ReactTooltip from "react-tooltip";

function ClientPlantForm(props) {
    const {
        setLoading,
        update,
        edit,
        defaults,
        clientId,
        setServerError,
        setEditPlant,
    } = props;

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [sending, setSending] = useState(false);

    const fields = {
        name: "",
    };

    useEffect(() => {
        // console.log("init");
        setValues(defaults ?? fields);
        setErrors(fields);
    }, []);

    const schema = yup.object().shape({
        name: yup.string().required("por favor ingrese un nombre").max(64),
    });

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios({
                    method: edit ? "put" : "post",
                    url:
                        "/api/" +
                        (edit
                            ? `plants/${edit}`
                            : `clients/${clientId}/plants`),
                    data: values,
                })
                    .then((res) => {
                        // console.log(res);
                        setSending(false);
                        update();
                        setValues(fields);
                    })
                    .catch((err) => {
                        console.error(err);
                        setServerError(err.response.data.message);
                        setSending(false);
                    })
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setErrors({ ...errors, ...errBuff });
    }

    return (
        <div className="d-flex my-2">
            <div className="flex-grow-1">
                <input
                    className={
                        "border w-100" + (errors.name ? " border-danger" : "")
                    }
                    type="text"
                    name="name"
                    placeholder="Nueva planta"
                    value={values.name || ""}
                    onChange={(e) => changeValue("name", e.target.value)}
                />

                <small className="text-danger">{errors.name}</small>
            </div>

            <div>
                {sending ? (
                    <span className="px-2">
                        <Spinner color="success" size="sm" />
                    </span>
                ) : edit ? (
                    <>
                        <Button
                            color="link"
                            onClick={handleSubmit}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttUpdatePlant"
                        >
                            <Save color="#009955" size="24" />
                        </Button>

                        <ReactTooltip id="ttUpdatePlant" effect="solid">
                            Guardar cambios
                        </ReactTooltip>

                        <Button
                            color="link"
                            onClick={() => setEditPlant(0)}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttCancel"
                        >
                            <SkipBack color="gray" size="24" />
                        </Button>

                        <ReactTooltip id="ttCancel" effect="solid">
                            Cancelar edición
                        </ReactTooltip>
                    </>
                ) : (
                    <>
                        <Button
                            color="link"
                            onClick={handleSubmit}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttCreatePlant"
                        >
                            <PlusCircle color="#009955" size="24" />
                        </Button>

                        <ReactTooltip id="ttCreatePlant" effect="solid">
                            Guardar planta
                        </ReactTooltip>
                    </>
                )}
            </div>
        </div>
    );
}

export default ClientPlantForm;
