import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../Loading";
import FormModal from "../../FormModal";
import ReportForm from "./ReportForm";
import ReportFilters from "./ReportFilters";
import ReportList from "./ReportList";
import PaginationLinks from "../../PaginationLinks";
import { Button } from "reactstrap";

function ReportsMain(props) {
    const {
        isClient,
        clients,
        curClient,
        curSpecies,
        setCurSpecies,
        filters,
        setFilters,
        resetFilters,
        filterQuery,
        setFilterQuery,
    } = props;

    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [modal, setModal] = useState(false);
    const [curId, setCurId] = useState(0);
    const [defaults, setDefaults] = useState({});

    const [species, setSpecies] = useState([]);
    const [clientPlants, setClientPlants] = useState([]);
    const [seasons, setSeasons] = useState([]);

    useEffect(() => {
        axios("/api/species?all=true")
            .then((res) => setSpecies(res.data))
            .catch((err) => console.error(err));

        axios("/api/seasons")
            .then((res) => setSeasons(res.data))
            .catch((err) => console.error(err));
    }, []);

    const [reports, setReports] = useState({ data: [], meta: {} });
    useEffect(() => {
        setIsLoading(true);

        if (curClient.id) {
            const url =
                `/api/reports?client=${curClient.id}` +
                (isClient ? `&draft=0` : "") +
                (curSpecies.id ? `&species=${curSpecies.id}` : "");

            axios
                .get(url + filterQuery)
                .then((res) => {
                    setReports(res.data);
                    setIsLoading(false);
                })
                .catch((err) => console.error(err));
        } else {
            axios
                .get("/api/reports?" + filterQuery.substring(1))
                .then((res) => {
                    setReports(res.data);
                    setIsLoading(false);
                })
                .catch((err) => console.error(err));
        }
    }, [filterQuery, status, curClient, curSpecies]);

    function toggle() {
        if (modal) {
            setCurId(0);
        }

        setModal(!modal);
    }

    async function editReport(id) {
        let report;

        try {
            const response = await axios(`/api/reports/${id}/details`);
            report = response.data;
        } catch (error) {
            return error;
        }

        if (report.comments == "null") {
            report.comments = "";
        }

        setCurId(report.id);

        setDefaults({
            client: report.client,
            draft: Boolean(report.draft),
            species: report.species,
            plant: report.plant,
            season: report.season,
            date: new Date(report.date),
            documents: report.documents.map((document) => {
                return {
                    id: document.id,
                    type: document.type,
                    file: "",
                    comments: document.comments.map((comment) => comment.body),
                    oldImages: document.images,
                    images: [],
                    updateFile: false,
                    action: "update",
                };
            }),
        });

        if (curClient.id === 0) {
            const client = clients.find(
                (client) => client.id === report.client
            );
            setClientPlants(client.plants);
        } else {
            setClientPlants(curClient.plants);
        }

        toggle();
        return '';
    }

    function update() {
        setStatus(status + 1);
    }

    function paginate(direction) {
        setIsLoading(true);

        axios
            .get(reports.links[direction])
            .then((res) => {
                setReports(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }

    return (
        <>
            {isClient ? (
                <h2 id="species-name" className="one text-dark mb-5">
                    {curClient.name} - {curSpecies.name}
                </h2>
            ) : (
                <div id="report-header">
                    <h2 className="one text-dark">
                        {curClient.id ? curClient.name : "Últimos reportes"}
                    </h2>

                    {Boolean(curClient.id) && (
                        <Button
                            id="new-report-btn"
                            outline
                            color="primary"
                            onClick={toggle}
                            className="float-right btn-sqr"
                        >
                            Nuevo Reporte
                        </Button>
                    )}

                    <FormModal
                        title={(curId ? "Editar" : "Nuevo") + " Reporte"}
                        modal={modal}
                        toggle={toggle}
                        submit="Guardar"
                        isLoading={sending}
                        form="reportForm"
                    >
                        <ReportForm
                            setSending={setSending}
                            setIsLoading={setIsLoading}
                            toggle={toggle}
                            update={update}
                            clientId={curClient.id}
                            species={species}
                            plants={curClient.plants ?? clientPlants}
                            seasons={seasons}
                            edit={curId}
                            defaults={defaults}
                        />
                    </FormModal>
                </div>
            )}

            <ReportFilters
                isClient={isClient}
                clientSelected={Boolean(curClient.id)}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                setFilterQuery={setFilterQuery}
                species={curClient.species ?? species}
                setCurSpecies={setCurSpecies}
                plants={curClient.plants ?? []}
                seasons={seasons}
            />

            {isLoading ? (
                <Loading />
            ) : (
                <div>
                    {reports.data.length > 0 ? (
                        <>
                            <ReportList
                                isClient={isClient}
                                clientSelected={Boolean(curClient.id)}
                                reports={reports.data}
                                setIsLoading={setIsLoading}
                                update={update}
                                editReport={editReport}
                                toggle={toggle}
                            />

                            <PaginationLinks
                                meta={reports.meta}
                                paginate={paginate}
                                item="reportes"
                            />
                        </>
                    ) : (
                        <p className="pt-3 font-weight-bold">
                            No se encontraron reportes.
                        </p>
                    )}
                </div>
            )}
        </>
    );
}

export default ReportsMain;
