import React, { useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import Account from "./Account";
import ReportsMain from "./reports/ReportsMain";
import Loading from "../Loading";

function Content(props) {
    const { route, isClient } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [curSpecies, setCurSpecies] = useState({ id: 0 });
    const [user, setUser] = useState({ id: isClient });
    const [curClient, setCurClient] = useState({ id: isClient });
    const [clients, setClients] = useState([]);

    useEffect(() => {
        if (isClient) {
            axios
                .get("/user")
                .then((res) => {
                    setUser(res.data);
                    setCurClient(res.data.clients[0]);
                    setCurSpecies(res.data.species[0]);
                    setIsLoading(false);
                })
                .catch((err) => console.error(err));
        } else {
            axios
                .get("/api/clients")
                .then((res) => {
                    setClients(res.data);
                    setIsLoading(false);
                })
                .catch((err) => console.error(err));
        }
    }, []);

    // Filters
    const emptyFilters = {
        draft: -1,
        species: 0,
        plant: 0,
        season: 0,
        date: "",
    };
    const [filters, setFilters] = useState(emptyFilters);
    const [filterQuery, setFilterQuery] = useState("");

    function resetFilters() {
        setFilters(emptyFilters);
        setFilterQuery("");
    }
    // Filters end

    if (route == "das") {
        return (
            <>
                <SideMenu
                    isLoading={isLoading}
                    user={user}
                    curClient={curClient}
                    setCurClient={setCurClient}
                    isClient={isClient}
                    curSpecies={curSpecies}
                    setCurSpecies={setCurSpecies}
                    clients={clients}
                    resetFilters={resetFilters}
                />

                <div id="dashboard-main">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <ReportsMain
                            isClient={isClient}
                            clients={clients}
                            curClient={curClient}
                            curSpecies={curSpecies}
                            setCurSpecies={setCurSpecies}
                            filters={filters}
                            setFilters={setFilters}
                            resetFilters={resetFilters}
                            filterQuery={filterQuery}
                            setFilterQuery={setFilterQuery}
                        />
                    )}
                </div>
            </>
        );
    } else if (route == "acc") {
        return <Account />;
    }
}

export default Content;
