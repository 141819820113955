import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loading from '../Loading';
import axios from "axios";

function MailMain() {
    const [userId, setUserId] = useState(0);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(true);

    const [users, setUsers] = useState([]);
    useEffect(() => {
        axios('/api/users')
            .then(res => {
                setUsers(res.data);
                setLoading(false);
            })
            .catch(err => console.error(err));
    }, []);

    function testo() {
        axios
            .post(`/mail/${type}/${userId}`)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.error(err));
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <h1 className="d-inline nine text-dark text-uppercase">
                        Correo
                    </h1>

                    {
                        loading ? <Loading /> :
                        <form className="mt-5">
                            <select value={type} onChange={e => setType(e.target.value)}>
                                <option value="" disabled hidden>Tipo de correo</option>
                                <option value="report">Reporte</option>
                                <option value="welcome">Bienvenida</option>
                            </select>
                                
                            <select value={userId} onChange={e => setUserId(e.target.value)}>
                                <option value="0" disabled hidden>Usuario</option>

                                {users.map(user => [
                                    <option value={user.id}>
                                        {user.name}
                                    </option>
                                ])}
                            </select>
                        </form>
                    }

                    <button onClick={testo}>probar</button>
                </div>
            </div>
        </div>
    );
}

const root = document.getElementById("mailtest");
if (root) {
    ReactDOM.render(<MailMain />, root);
}
