import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";

function LinkSlider(props) {
    const { titulo, link, href } = props;
    const { t, i18n } = useTranslation();

    function createMarkup() {
        return { __html: t(titulo) };
    }

    return (
        <div>
            <div
                className="tituloSlider"
                dangerouslySetInnerHTML={createMarkup()}
            ></div>
            <a href={href} className="slideA">
                {t(link)}
                <ChevronDown color="white" />
            </a>
        </div>
    );
}

export default LinkSlider;
