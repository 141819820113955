import React from "react";

function Roles(props) {
    return (
        <>
            {props.roles.map((role) => (
                <span
                    key={role.id}
                    className={
                        "mr-1 badge badge-pill badge-" + colorBadge(role.name)
                    }
                >
                    {role.name}
                </span>
            ))}
        </>
    );

    function colorBadge(role) {
        let color;

        switch (role) {
            case "admin":
                color = "dark";
                break;

            case "owner":
                color = "secondary";
                break;

            case "agro":
                color = "success";
                break;

            case "editor":
                color = "warning";
                break;

            case "client":
                color = "info";
                break;

            default:
                color = "light";
                break;
        }

        return color;
    }
}

export default Roles;
