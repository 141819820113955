import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    FormGroup,
    Input,
    CustomInput as SpecialInput,
    Label,
    FormText,
} from "reactstrap";

// INPUT
function CustomInput(props) {
    const {
        value,
        type,
        name,
        dName,
        outerDiv,
        innerDiv,
        labelClass,
        autocomplete,
        placeholder,
        tag,
        autoFocus,
        touched,
        error,
        changeValue,
        isRequired,
        charCount,
        limit,
    } = props;

    return (
        <FormGroup className={outerDiv}>
            <Label for={name} className={labelClass}>
                {dName}
                <span className="text-danger">
                    {isRequired(name) ? "*" : ""}
                </span>
            </Label>
            <div className={innerDiv}>
                <Input
                    className={
                        touched ? (error ? " is-invalid" : " is-valid") : ""
                    }
                    type={type}
                    name={name}
                    value={value}
                    autoComplete={autocomplete || ""}
                    placeholder={placeholder}
                    tag={tag}
                    autoFocus={autoFocus}
                    onChange={(e) => changeValue(name, e.target.value)}
                />
                <small className="text-danger">{error}</small>
                {charCount && (
                    <FormText className="float-right">
                        {value ? value.length : 0}
                        {limit && ` / ${limit}`} caracteres
                    </FormText>
                )}
            </div>
        </FormGroup>
    );
}

// SWITCH
function SwitchInput(props) {
    const {
        value,
        name,
        dName,
        outerDiv,
        // innerDiv,
        // labelClass,
        touched,
        error,
        changeValue,
        // isRequired,
    } = props;

    return (
        <FormGroup check className={"form-group " + outerDiv}>
            {/* <SpecialInput
                id={name}
                className={
                    touched ? (error ? " is-invalid" : " is-valid") : ""
                }
                type="switch"
                name={name}
                label={dName}
                checked={value}
                onChange={() => changeValue(name, !value)}
            /> */}

            <Label check>
                <Input
                    id={name}
                    className={
                        touched ? (error ? " is-invalid" : " is-valid") : ""
                    }
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={() => changeValue(name, !value)}
                />{" "}
                {dName}
            </Label>
        </FormGroup>
    );
}

// SELECT
function CustomSelect(props) {
    const { t, i18n } = useTranslation();

    const {
        value,
        name,
        dName,
        outerDiv,
        innerDiv,
        labelClass,
        options,
        defaultOption,
        touched,
        error,
        changeValue,
        isRequired,
        multiple,
    } = props;

    function getOptions(selectedOptions) {
        const optArray = Array.from(selectedOptions, (option) => option.value);
        changeValue(name, optArray);
    }

    return (
        <FormGroup className={outerDiv}>
            <Label for={name} className={labelClass}>
                {dName}

                <span className="text-danger">
                    {isRequired(name) ? "*" : ""}
                </span>
            </Label>

            <div className={innerDiv}>
                <select
                    className={
                        "custom-select" +
                        (touched ? (error ? " is-invalid" : " is-valid") : "")
                    }
                    name={name}
                    value={String(value)}
                    onChange={(e) =>
                        multiple
                            ? getOptions(e.target.selectedOptions)
                            : changeValue(name, e.target.value)
                    }
                    multiple={multiple}
                >
                    <option value={0} disabled={!defaultOption}>
                        {t(
                            defaultOption || "Seleccione una opción"
                        )}
                    </option>

                    {options.map((option) => (
                        <option
                            key={option.id}
                            value={option.id}
                            className="text-capitalize"
                        >
                            {option.name}
                        </option>
                    ))}
                </select>

                <small className="text-danger">{error}</small>
            </div>
        </FormGroup>
    );
}

// RADIO
function CustomRadio(props) {
    const {
        value,
        name,
        dName,
        outerDiv,
        innerDiv,
        labelClass,
        optionClass,
        options,
        touched,
        error,
        changeValue,
        isRequired,
    } = props;

    return (
        <FormGroup className={outerDiv}>
            <p className={labelClass + (touched ? (error ? " is-invalid" : " is-valid") : "")}>
                {dName}
                <span className="text-danger">
                    {isRequired(name) ? "*" : ""}
                </span>
            </p>

            <div className={innerDiv}>
                {options.map((option) => (
                    <label
                        key={option.id}
                        className={optionClass}
                    >
                        <input
                            type="radio"
                            name={name}
                            value={option.id}
                            checked={value == option.id}
                            className="pr-1"
                            onChange={(e) => changeValue(name, e.target.value)}
                        />

                        {option.name}
                    </label>
                ))}

                <small className="text-danger">{error}</small>
            </div>
        </FormGroup>
    );
}

// SINGLE FILE
import { Upload } from "react-feather";

function CustomFile(props) {
    const {
        name,
        dName,
        outerDiv,
        innerDiv,
        labelClass,
        touched,
        isRequired,
        error,
        addFile,
        help,
    } = props;

    return (
        <FormGroup className={outerDiv}>
            <Label for={name} className={labelClass}>
                {dName}
                <span className="text-danger">
                    {isRequired(name) ? "*" : ""}
                </span>
            </Label>
            <div className={innerDiv}>
                <SpecialInput
                    className={
                        touched ? (error ? " is-invalid" : " is-valid") : ""
                    }
                    valid={touched && !error}
                    invalid={touched && error ? true : false}
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="customFile"
                    onChange={(e) => addFile(name, e.target.files[0])}
                />
                <FormText>{help}</FormText>
                <small className="text-danger">{error}</small>
            </div>
        </FormGroup>
    );
}

// ImgDrop
function ImgDrop(props) {
    const { image, name, update, error, expHeight } = props;

    const [preview, setPreview] = useState(image);
    const [hovering, setHovering] = useState(false);
    const [dragging, setDragging] = useState(false);

    function drop(event) {
        addFile(event.dataTransfer.files[0]);
        setDragging(false);
    }

    function addFile(file) {
        setPreview(URL.createObjectURL(file));
        update(name, file);
    }

    function dragHover(event, state) {
        event.preventDefault();
        setHovering(state);
        setDragging(state);
    }

    return (
        <div
            className={`img-upload${dragging ? "-dragging" : ""} ${error ? "border-danger" : ""}`}
            onDragOver={(e) => dragHover(e, true)}
            onDragLeave={(e) => dragHover(e, false)}
            onDrop={drop}
            style={preview ? {} : { height: expHeight }}
        >
            <input
                className="img-input"
                type="file"
                accept="image/*"
                onChange={(e) => addFile(e.target.files[0])}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            />

            <div className="position-relative h-100 d-flex flex-column justify-content-center">
                <img src={preview} alt="" className="img-preview" />

                <div
                    className={`img-drop${preview
                        ? "-preview" + (hovering ? "-hovering" : "")
                        : ""
                        }`}
                >
                    <Upload
                        color="gray"
                        size={50}
                        className="text-center mb-3"
                    />

                    <p>Haz click o arrastra tu imagen aquí</p>
                </div>
            </div>
        </div>
    );
}

// WYSIWYG
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Wysiwyg(props) {
    const {
        name,
        value,
        changeValue,
        toolbarClass,
        wrapperClass,
        editorClass,
        placeholder,
        toolbarOnFocus,
    } = props;

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const blocksFromHtml = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(blocksFromHtml);
        const defaultEditorState = EditorState.createWithContent(contentState);
        setEditorState(defaultEditorState);
    }, []);

    useEffect(() => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        changeValue(name, html);
    }, [editorState]);

    return (
        <div>
            <Editor
                editorState={editorState}
                toolbarClassName={toolbarClass}
                wrapperClassName={wrapperClass}
                editorClassName={editorClass}
                onEditorStateChange={setEditorState}
                toolbar={{
                    options: [
                        "inline",
                        "blockType",
                        "list",
                        "textAlign",
                        "link",
                        "history",
                        "embedded",
                    ],
                    inline: {
                        inDropdown: false,
                        options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                        ],
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    embedded: {
                        icon: "/images/back/youtube.svg",
                        embedCallback: (code) =>
                            `https://www.youtube.com/embed/${code}?controls=0`,
                    },
                }}
                placeholder={placeholder}
                stripPastedStyles
                toolbarOnFocus={toolbarOnFocus}
            />
        </div>
    );
}

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDate(props) {
    const {
        value,
        changeValue,
        name,
        dName,
        isRequired,
        outerDiv,
        innerDiv,
        labelClass,
        inputClass,
        touched,
        error,
        placeholder,
        time,
        minDate,
        maxDate,
    } = props;

    const ExampleCustomInput = ({ value, onClick }) => (
        <input
            className={
                inputClass +
                (touched ? (error ? " is-invalid" : " is-valid") : "")
            }
            name={name}
            type="text"
            defaultValue={value}
            onClick={onClick}
            autoComplete="off"
            placeholder={placeholder}
        />
    );

    return (
        <FormGroup className={outerDiv}>
            <Label for={name} className={labelClass}>
                {dName}
                <span className="text-danger">
                    {isRequired(name) ? "*" : ""}
                </span>
            </Label>

            <div className={innerDiv}>
                <DatePicker
                    dateFormat={"dd-MM-yyyy" + (time ? " HH:mm" : "")}
                    timeInputLabel="Hora:"
                    showTimeInput={time}
                    selected={value}
                    onChange={(date) => changeValue(name, date)}
                    minDate={minDate}
                    maxDate={maxDate || new Date()}
                    customInput={<ExampleCustomInput />}
                    withPortal
                    placeholderText={placeholder}
                />
                <small className="text-danger">{error}</small>
            </div>
        </FormGroup>
    );
}

export {
    CustomInput,
    SwitchInput,
    CustomSelect,
    CustomRadio,
    CustomFile,
    ImgDrop,
    Wysiwyg,
    CustomDate,
};
