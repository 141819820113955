import React from "react";
import { useTranslation } from "react-i18next";
import { Spring, animated } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

function Case(props) {
    const { t, i18n } = useTranslation();

    return (
        <div className="trustCase">
            <p className="eight mb-0">
                +
                <VisibilitySensor>
                    {({ isVisible }) => (
                        <Spring
                            from={{ number: 0 }}
                            to={{ number: isVisible ? props.number : 0 }}
                            config={{ mass: 5, tension: 280, friction: 90 }}
                        >
                            {(props) => (
                                <animated.span>
                                    {props.number.toFixed(0)}
                                </animated.span>
                            )}
                        </Spring>
                    )}
                </VisibilitySensor>
                <span className="nine text-uppercase">
                    {" " + t(props.unit)}
                </span>
            </p>

            <p className="ten mb-0">{t(props.text)}</p>
        </div>
    );
}

export default Case;
