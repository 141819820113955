import React from "react";

function QualityPolicy() {
    return (
        <section
            id="calidad"
            className="container mt-4 d-flex flex-column align-items-center"
        >
            <div className="two text-center">
                Política de Calidad e Inocuidad
            </div>

            <div className="col-lg-10 mb-5 seven">
                <p>
                    PSS Envases SPA y Bolsa Pac Ltda. somos empresa del sector
                    packaging, dedicada a la{" "}
                    <strong>
                        fabricación y comercialización de envases flexibles
                    </strong>{" "}
                    para todos los rubros productivos, comerciales, de
                    servicios, que en la actualidad ofrece una variada gama de
                    envases técnicos de acuerdo a los requerimientos de nuestros
                    clientes.
                </p>

                <p>
                    Comprendemos perfectamente que los clientes siempre serán la
                    razón más valiosa para nuestra compañía, y por ellos nos
                    encontramos comprometidos a{" "}
                    <strong>
                        desarrollar y producir envases inocuos con higiene de
                        categoría elevada; productos técnicos innovadores de
                        alta calidad
                    </strong>
                    .
                </p>

                <div className="row justify-content-center">
                    <p className="col-md-10 font-weight-bold">
                        Impulsamos y protegemos el crecimiento de una cultura de
                        confianza, legal y ética, sustentable en el tiempo,
                        siempre comprometidos con el bienestar de nuestra
                        comunidad y medio ambiente.
                    </p>
                </div>

                <p>
                    Hemos implementado para ello, un completo{" "}
                    <strong className="underline-blue">
                        Sistema de Gestión de Calidad e Inocuidad
                    </strong>
                    , que apunta a la mejora continua, a través del trabajo en
                    equipo con{" "}
                    <strong>
                        participación activa de cada integrante de nuestra
                        Empresa
                    </strong>
                    , velando responsablemente por su formación y competencias
                    con la finalidad de minimizar todo riesgo en temas de{" "}
                    <strong className="underline-blue">
                        seguridad personal y de productos
                    </strong>
                    , velando por la seguridad tanto para personal empleado como
                    para contratistas, y para toda persona que nos visita,
                    protegiéndolos en todo sentido, cumpliendo a cabalidad las{" "}
                    <strong className="underline-blue">
                        Normativas Legales
                    </strong>{" "}
                    actuales que nos rigen, y{" "}
                    <strong className="underline-blue">
                        Normativas Internas
                    </strong>{" "}
                    desarrolladas, basadas en el bien común; todas directrices
                    que constituyen el principal apoyo para la consecución de
                    todos nuestros objetivos.
                </p>

                <p id="qp-version">
                    Código: REG-GG-01<br />
                    Versión: 003<br />
                    Fecha: <time dateTime="2020-03-01">01-03-2020</time>
                </p>

                <div className="text-right">
                    <a
                        href="/storage/docs/politica_calidad.pdf"
                        className="btn btn-outline-dark btn-sqr mt-2"
                    >
                        Descargar
                    </a>
                </div>
            </div>
        </section>
    );
}

export default QualityPolicy;
