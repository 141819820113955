import React, { useEffect, useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { Form, Alert } from "reactstrap";
import { CustomDate, CustomInput, CustomRadio, CustomSelect } from "../../FormFields";
import Loading from '../../Loading';
import staticOptions from './staticOptions';

function AnalyticsForm(props) {
    const { setResult, setSending } = props;

    const [options, setOptions] = useState({});
    const [loadingOptions, setLoadingOptions] = useState(true);
    const [timeFilters, setTimeFilters] = useState([]);
    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const [lastQuery, setLastQuery] = useState({});

    const fields = {
        indicator: "",
        dateFrom: null,
        dateTo: null,
        loginMethod: null,
        client: null,
        species: null,
        season: null,
        // UI only
        dateMode: "all",
        timeFilter: null
    };

    const minDate = new Date("2022-12-16T00:00");
    let maxDate = new Date();
    maxDate = new Date(maxDate.toDateString());

    useEffect(() => {
        axios("/api/analytics/options")
            .then(res => {
                setOptions({
                    indicators: [...staticOptions.indicators],
                    dateModes: [...staticOptions.dateModes],
                    timeFilters: [...staticOptions.timeFilters],
                    ...res.data
                });
                setLoadingOptions(false);
            })
            .catch(err => console.error(err));

        setValues(fields);

        const emptyFields = {};
        Object.keys(fields).forEach((key) => (emptyFields[key] = ""));
        setErrors(emptyFields);
    }, []);

    function addFilter(event) {
        event.preventDefault();

        const optionsBuffer = { ...options };
        const index = optionsBuffer.timeFilters.findIndex(filter => filter.id === values.timeFilter);

        if (index >= 0) {
            const newFilter = optionsBuffer.timeFilters.splice(index, 1)[0];
            setOptions(optionsBuffer);
            setTimeFilters([...timeFilters, newFilter]);
        }
    }

    function rmFilter(index) {
        const timeFiltersBuff = [...timeFilters];
        const damnedFilter = timeFiltersBuff.splice(index, 1)[0];
        setTimeFilters(timeFiltersBuff);

        const optionsBuffer = { ...options };
        optionsBuffer.timeFilters.push(damnedFilter);
        optionsBuffer.timeFilters.sort((a, b) => a.place - b.place);
        setOptions(optionsBuffer);

        const valuesBuff = { ...values };
        delete valuesBuff[damnedFilter.id];
        setValues(valuesBuff);
    }

    const schema = yup.object().shape({
        indicator: yup.string().required("Seleccione un indicador, por favor"),
        dateFrom: yup.date().nullable().min(minDate),
        dateTo: yup.date().nullable().max(maxDate),
        dayOfWeek: yup.number().nullable().integer().max(7),
        dayOfMonth: yup.number().nullable().integer().max(31, "Debe ser inferior o igual a 31"),
        dayOfQuarter: yup.number().nullable().integer().max(92, "Debe ser inferior o igual a 92"),
        dayOfYear: yup.number().nullable().integer().max(365, "Debe ser inferior o igual a 365"),
        weekOfMonth: yup.number().nullable().integer().max(5, "Debe ser inferior o igual a 5"),
        weekOfYear: yup.number().nullable().integer().max(53, "Debe ser inferior o igual a 53"),
        monthActual: yup.number().nullable().integer().max(12, "Debe ser inferior o igual a 12"),
        quarterActual: yup.number().nullable().integer().max(4, "Debe ser inferior o igual a 4"),
        yearActual: yup.number().nullable().integer().min(2022, "Debe ser igual o superior a 2022").max(2037, "Debe ser inferior o igual a 2037"),
        loginMethod: yup.number().nullable().integer(),
        client: yup.number().nullable().integer(),
        species: yup.number().nullable().integer(),
        season: yup.number().nullable().integer(),
        // UI only
        dateMode: yup.string().required("Seleccione un tiempo, por favor"),
        timeFilter: yup.string().nullable().trim(),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        if (value === "0") value = null;

        // console.log("updating value of " + field + " to:");
        // console.log(value);

        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function changeValues(newValues) {
        // console.log({ ...values, ...newValues });
        setValues({ ...values, ...newValues });

        if (touched) {
            for (const [field, value] of Object.entries(newValues)) {
                validate(field, value);
            }
        }
    }

    function changeIndicator(_field, value) {
        let newValues = { indicator: value };

        switch (value) {
            case "logins":
                newValues.species = null;
                newValues.season = null;
                break;

            case "visits":
                newValues.loginMethod = null;
                newValues.species = null;
                newValues.season = null;
                break;

            case "reportViews":
                newValues.loginMethod = null;
                break;

            case "reportDownloads":
                newValues.loginMethod = null;
                break;
        }

        changeValues(newValues);
    }

    function changeDateMode(_field, value) {
        let newValues = { dateMode: value };

        switch (value) {
            case "all":
                newValues.dateFrom = null;
                newValues.dateTo = null;
                break;

            case "exact":
                newValues.dateFrom = new Date(maxDate.valueOf());
                newValues.dateTo = null;
                break;

            case "range":
                newValues.dateFrom = new Date(minDate.valueOf());
                newValues.dateTo = new Date(maxDate.valueOf());
                break;
        }

        changeValues(newValues);
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        const query = Object.fromEntries(
            Object.entries(values)
                .filter(([_key, value]) => value)
        );

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios({
                    method: "post",
                    url: "/api/analytics",
                    data: values,
                })
                    .then((res) => {
                        setResult({
                            ok: true,
                            data: res.data,
                        });
                        setSending(false);
                        setLastQuery(query);
                    })
                    .catch((err) => {
                        setResult({ ok: false });
                        setLastQuery({});
                        console.error(err);
                        setServerError(err.response.data.message);
                        setSending(false);
                    })
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setErrors({ ...errors, ...errBuff });
    }

    return loadingOptions ? <Loading /> : (
        <Form onSubmit={handleSubmit}>
            <CustomRadio
                name="indicator"
                value={values.indicator}
                dName="Indicador"
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                optionClass="mr-3"
                options={options.indicators}
                touched={touched}
                error={errors.indicator}
                changeValue={changeIndicator}
                isRequired={isRequired}
            />

            <CustomRadio
                value={values.dateMode}
                name="dateMode"
                dName="Tiempo"
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                optionClass="mr-3"
                options={options.dateModes}
                touched={touched}
                error={errors.dateMode}
                changeValue={changeDateMode}
                isRequired={isRequired}
            />

            <div className="pl-4">
                {values.dateMode !== "all" && (
                    <CustomDate
                        value={values.dateFrom}
                        name="dateFrom"
                        dName={values.dateMode === "exact" ? "Fecha" : "Desde"}
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        inputClass="form-control"
                        touched={touched}
                        error={errors.dateFrom}
                        changeValue={changeValue}
                        isRequired={isRequired}
                        minDate={minDate}
                    />
                )}

                {values.dateMode === "range" && (
                    <CustomDate
                        value={values.dateTo}
                        name="dateTo"
                        dName="Hasta"
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        inputClass="form-control"
                        touched={touched}
                        error={errors.dateTo}
                        changeValue={(changeValue)}
                        isRequired={isRequired}
                        minDate={values.dateFrom}
                    />
                )}
            </div>

            <div>
                <div className="row">
                    <CustomSelect
                        value={values.timeFilter || 0}
                        name="timeFilter"
                        dName="Más filtros"
                        isRequired={isRequired}
                        outerDiv="col-md-10 row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        options={options.timeFilters}
                        touched={touched}
                        error={errors.timeFilter}
                        changeValue={changeValue}
                    />

                    <button onClick={addFilter} className="col-md-2 btn btn-sqr btn-outline-primary">Agregar filtro</button>
                </div>

                {timeFilters.map((filter, i) => (
                    <div key={i} className="row">
                        {filter.type === "number" ? (
                            <CustomInput
                                value={values[filter.id] || ""}
                                type="number"
                                name={filter.id}
                                dName={filter.name}
                                isRequired={() => false}
                                outerDiv="form-group row col-md-10"
                                innerDiv="col-md-7"
                                labelClass="col-md-4 col-form-label text-md-right"
                                touched={touched}
                                error={errors[filter.id]}
                                changeValue={changeValue}
                            />
                        ) : filter.type === "select" && (
                            <CustomSelect
                                value={values[filter.id] || 0}
                                name={filter.id}
                                dName={filter.name}
                                isRequired={() => false}
                                outerDiv="row col-md-10"
                                innerDiv="col-md-10"
                                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                                options={filter.options}
                                touched={touched}
                                error={errors[filter.id]}
                                changeValue={changeValue}
                            />
                        )}

                        <button
                            onClick={() => rmFilter(i)}
                            className="col-md-2 btn btn-sqr btn-outline-danger"
                        >
                            Eliminar
                        </button>
                    </div>
                ))}

                <div>
                    {values.indicator === "logins" && (
                        <CustomSelect
                            value={values.loginMethod || 0}
                            name="loginMethod"
                            dName="Método de inicio de sesión"
                            isRequired={isRequired}
                            outerDiv="row col-md-10"
                            labelClass="col-md-2 col-form-label text-md-right"
                            options={options.loginMethods}
                            defaultOption="Todos"
                            touched={touched}
                            error={errors.loginMethod}
                            changeValue={changeValue}
                        />
                    )}

                    <CustomSelect
                        value={values.client || 0}
                        name="client"
                        dName="Cliente"
                        isRequired={isRequired}
                        outerDiv="row col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right"
                        options={options.clients}
                        defaultOption="Todos"
                        touched={touched}
                        error={errors.client}
                        changeValue={changeValue}
                    />

                    {values.indicator.startsWith("report") && (
                        <>
                            <CustomSelect
                                value={values.species || 0}
                                name="species"
                                dName="Especie"
                                isRequired={isRequired}
                                outerDiv="row col-md-10"
                                labelClass="col-md-2 col-form-label text-md-right"
                                options={options.species}
                                defaultOption="Todas"
                                touched={touched}
                                error={errors.species}
                                changeValue={changeValue}
                            />

                            <CustomSelect
                                value={values.season || 0}
                                name="season"
                                dName="Temporada"
                                isRequired={isRequired}
                                outerDiv="row col-md-10"
                                labelClass="col-md-2 col-form-label text-md-right"
                                options={options.seasons}
                                defaultOption="Todas"
                                touched={touched}
                                error={errors.season}
                                changeValue={changeValue}
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="d-flex flex-column align-items-end">
                <p className="text-danger">*Campos obligatorios</p>
            </div>

            <button
                onClick={handleSubmit}
                className="btn btn-sqr btn-outline-primary"
            >
                Realizar consulta
            </button>

            <Alert
                color="danger"
                isOpen={serverError ? true : false}
                toggle={() => setServerError("")}
                className="mt-3"
            >
                {serverError}
            </Alert>

            {Object.values(lastQuery).length > 0 && (
                <div className="mt-4">
                    <h2>Última consulta:</h2>

                    <p>
                        {staticOptions.dictionary[lastQuery.indicator] + " "}

                        {lastQuery.dateMode === "all"
                            ? "en todos los tiempos"
                            : `desde ${lastQuery.dateFrom.toLocaleDateString()}`
                            + (lastQuery.dateMode === "range"
                                ? ` hasta ${lastQuery.dateTo.toLocaleDateString()}`
                                : "")
                        }

                        {staticOptions.timeFilters.map(filter => {
                            if (Object.keys(lastQuery).includes(filter.id)) {
                                let value = lastQuery[filter.id];
                                if (!!filter.options) value = filter.options.find(option => option.id == value).name;
                                return `, ${staticOptions.dictionary[filter.id]} ${value}`;
                            } else {
                                return "";
                            }
                        })}

                        {Object.entries(lastQuery).map(([key, value]) => {
                            const filterKeys = ["client", "species", "season"];

                            if (filterKeys.includes(key)) {
                                const plural = key.endsWith("s") ? key : key + "s";
                                value = options[plural].find(option => option.id == value).name;
                                return `, ${staticOptions.dictionary[key]}: ${value}`;
                            } else {
                                return "";
                            }
                        })}

                        .
                    </p>
                </div>
            )}
        </Form>
    );
}

export default AnalyticsForm;