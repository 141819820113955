import React from "react";
import { Badge, Button, Spinner, Toast, ToastBody } from "reactstrap";

function ReportMobile(props) {
    const {
        index,
        report,
        isClient,
        parseTS,
        getLinks,
        editReport,
        deleteReport,
    } = props;

    return (
        <Toast className="mb-2">
            <div
                id={"heading" + report.id}
                className="toast-header d-block pointer"
                data-toggle="collapse"
                href={"#collapse" + report.id}
                aria-expanded="false"
                aria-controls={"collapse" + report.id}
            >
                <div className="d-flex justify-content-between py-1 text-capitalize">
                    <strong>{`${report.client} - ${report.species}`}</strong>

                    <small>{report.season}</small>
                </div>

                <h5 className="mb-1">
                    <Badge
                        color={report.draft ? "secondary" : "primary"}
                        className="mr-2"
                    >
                        {parseTS(report.date)}
                    </Badge>

                    <span>{report.plant}</span>
                </h5>
            </div>

            <ToastBody
                id={"collapse" + report.id}
                className="collapse"
                aria-labelledby={"heading" + report.id}
                data-parent="#reports-mobile"
            >
                <p className="d-flex justify-content-between mb-2 border-bottom border-light">
                    <span>Subido por:</span>

                    <strong>
                        {isClient ? report.editor : report.uploader}
                    </strong>
                </p>
                {!isClient && (
                    <div>
                        <p className="d-flex justify-content-between mb-2 border-bottom border-light">
                            <span>Última edición:</span>

                            <strong>{report.editor}</strong>
                        </p>

                        <p className="d-flex justify-content-between mb-2 border-bottom border-light">
                            <span>Estado:</span>

                            <strong>
                                {report.draft ? "Borrador" : "Enviado"}
                            </strong>
                        </p>
                    </div>
                )}

                <div className="d-flex justify-content-between pt-2">
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => getLinks(report.id)}
                    >
                        Ver
                    </Button>

                    {!isClient && (
                        <>
                            <Button
                                color="warning"
                                size="sm"
                                onClick={() => editReport(report.id)}
                            >
                                Editar
                            </Button>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={() => deleteReport(report.id)}
                            >
                                Eliminar
                            </Button>
                        </>
                    )}
                </div>
            </ToastBody>
        </Toast>
    );
}

export default ReportMobile;
