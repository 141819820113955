import React from "react";
import { useTranslation } from "react-i18next";
import Case from "./Case";

function Trust(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Confían en nosotros")}</h2>

            <div className="d-flex">
                <Case
                    number={10}
                    unit="Países"
                    text="son el destino de llegada de nuestros envases"
                />

                <div style={{ width: 12 + "%" }} />

                <Case
                    number={200}
                    unit="Marcas"
                    text="prefieren nuestra tecnología"
                />
            </div>
        </section>
    );
}

export default Trust;
