import React, { useState } from "react";
import { PlusCircle, XCircle, RotateCcw } from "react-feather";
import { mixed } from "yup";

function DocumentImages(props) {
    const { oldImages, images, rmImages, error, changeValue } = props;

    const [dragging, setDragging] = useState(false);
    const [imgErrors, setImgErrors] = useState([]);

    const schema = mixed()
        .required()
        .test(
            "fileSize",
            "Archivo demasiado pesado. Peso máximo: 200 KB.",
            (value) => value && value.size <= 200 * 1024
        )
        .test(
            "fileFormat",
            "Formato no soportado. Debe ser jpg o png.",
            (value) =>
                value &&
                ["image/jpeg", "image/png"].includes(value.type)
        );

    async function addFile(event) {
        setImgErrors([]);
        let imgErrorsBuff = [];
        let newImages = [];

        for (const file of event.target.files) {
            await schema.validate(file, { abortEarly: false })
                .then(_res => newImages.push(file))
                .catch(err => imgErrorsBuff.push({
                    name: file.name,
                    errors: err.errors,
                }));
        }

        changeValue("images", [...images, ...newImages]);
        setImgErrors(imgErrorsBuff);
        setDragging(false);
    }

    function rmFile(index) {
        const imagesBuff = images;
        imagesBuff.splice(index, 1);
        changeValue("images", imagesBuff);
    }

    function rmOldImage(id) {
        changeValue("rmImages", [...rmImages, id]);
    }

    function restoreOldImage(id) {
        let rmImagesBuff = rmImages;
        rmImagesBuff = rmImagesBuff.filter(rmId => rmId != id);
        changeValue("rmImages", rmImagesBuff);
    }

    return <div className="mb-3 p-3 border rounded">
        <h4>Imágenes</h4>

        {oldImages.map(image => (
            <div key={image.id} className="position-relative">
                {rmImages.includes(image.id) ? (
                    <RotateCcw
                        className="img-rm text-success" size={48}
                        onClick={() => restoreOldImage(image.id)}
                    />
                ) : (
                    <XCircle
                        className="img-rm text-danger" size={48}
                        onClick={() => rmOldImage(image.id)}
                    />
                )}

                <img
                    src={image.url}
                    alt=""
                    className={"my-2 w-100" + (rmImages.includes(image.id) ? " img-doomed" : "")}
                />
            </div>
        ))}

        {images.map((image, i) => (
            <div key={i} className="position-relative">
                <XCircle
                    className="img-rm text-danger" size={48}
                    onClick={() => rmFile(i)}
                />

                <img src={URL.createObjectURL(image)} alt="" className="my-2 w-100" />
            </div>
        ))}

        <div
            className={`py-4 text-center text-secondary img-upload${dragging ? "-dragging" : ""} ${error ? "border-danger" : ""}`}
            onDragOver={() => setDragging(true)}
            onDragLeave={() => setDragging(false)}
        >
            <input
                className="img-input"
                type="file"
                accept="image/*"
                multiple
                onChange={addFile}
            />

            <div>
                <PlusCircle className="pr-2" />
                Haz click o arrastra tu imagen aquí
            </div>

            {imgErrors && imgErrors.map((imgError, i) => (
                <div key={i} className="mt-1">
                    <small className="text-danger font-weight-bold d-block">{imgError.name}:</small>

                    {imgError.errors.map(errorText => (
                        <small key={i} className="text-danger d-block">{errorText}</small>
                    ))}
                </div>
            ))}
        </div>

        <small className="text-danger">{error}</small>
    </div>;
}

export default DocumentImages;