import React from "react";
import { Twitter } from "react-feather";
import { Linkedin } from "react-feather";

function Footer() {
    return (
        <footer className="container mt-5">
            <div id="footer">
                <img
                    src="/images/paclife_logo_blanco.png"
                    className="mr-2"
                    width="50"
                    height="50"
                />

                <div className="seven">
                    <div className="d-flex justify-content-end align-items-center">
                        <span id="footer-brand" className="mr-3">
                            PACLIFE
                        </span>

                        <div id="rrss">
                            <a
                                href="https://www.linkedin.com/company/paclife/"
                                className="mr-2"
                            >
                                <Linkedin />
                            </a>

                            <a href="https://twitter.com/paclife_envases">
                                <Twitter />
                            </a>
                        </div>
                    </div>

                    <address className="text-right">
                        Los Duraznos 0683, La Pintana, Región Metropolitana,
                        Chile
                    </address>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
