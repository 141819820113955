import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import { ChevronDown } from "react-feather";

function ItemList(props) {
    const { category, items, isOpen, selected } = props;
    const { t } = useTranslation();

    function isSelected(name) {
        if (selected == name) {
            return "five";
        } else {
            return "productItem";
        }
    }

    return (
        <div>
            <div className="techListButton">
                <span className="four">{category}</span>
                <ChevronDown />
            </div>

            <menu className="itemList">
                {items.map((item) => (
                    <li key={item.id}>
                        <a
                            href={
                                "/products/" +
                                (category === "Especies" ? "species/" : "") +
                                item.slug
                            }
                            className={isSelected(item.name)}
                        >
                            | {t(item.name)}
                        </a>
                    </li>
                ))}
            </menu>
        </div>
    );
}

export default ItemList;
