import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useController } from "react-scroll-parallax";
import { ChevronDown, ChevronUp } from "react-feather";
import { Collapse } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Support(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    const { parallaxController } = useController();
    useEffect(() => parallaxController.update());

    const [selected, setSelected] = useState(-1);

    const phases = [
        {
            name: "Reunión técnica",
            img: "/images/advisory/1_reunion_tecnica.png",
            description: [
                "Reunión realizada previo a la temporada con cada cliente.",
                "Identificación de problemas y oportunidades de mejora.",
                "Se define plan de trabajo, estrategias y necesidades.",
                "Se proponen metas y se replantean los pasos a seguir.",
            ],
        },
        {
            name: "Propuesta trabajo",
            img: "/images/advisory/2_propuesta.png",
            description: [
                "Propuesta de servicio de post venta definida y aprobada por cliente.",
                "Presentación de asistente técnico a cargo y planificación para la temporada.",
            ],
        },
        {
            name: "Visitas periódicas",
            img: "/images/advisory/3_inspeccion.png",
            description: [
                "Constantes asesorías realizadas a cada centro de embalaje.",
                "Visitas durante toda la temporada y seguimiento en destino.",
                "Monitoreo de puntos críticos, claves para uso correcto de envases.",
            ],
        },
        {
            name: "Reunión cierre de temporada",
            img: "/images/advisory/4_reunion_cierre.png",
            description: [
                "Análisis técnico de puntos críticos recopilados en toda la temporada.",
                "Evaluación de desempeño tanto del envase como de la planta de servicio.",
                "Se identifican problemas y oportunidades para mejorar continua.",
            ],
        },
    ];

    function toggle(index) {
        setSelected(selected === index ? -1 : index);
    }

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Asesoría Técnica")}</h2>

            <Carousel
                additionalTransfrom={0}
                centerMode={false}
                draggable
                focusOnSelect={false}
                infinite
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 768,
                        },
                        items: 4,
                        partialVisibilityGutter: 40,
                    },
                    mobile: {
                        breakpoint: {
                            max: 767,
                            min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                    },
                }}
                showDots={false}
                show
                slidesToSlide={1}
                swipeable
            >
                {phases.map((phase, i) => (
                    <div key={i}>
                        <div
                            className={
                                "advice pointer" +
                                (selected === i ? "" : " fantasma")
                            }
                            onClick={() => toggle(i)}
                        >
                            <img src={phase.img} alt={phase.name} className="img-fluid" />

                            <h3 className="one mt-4 text-center text-uppercase">
                                {t(phase.name)}
                            </h3>

                            {selected === i ? (
                                <ChevronUp
                                    size={40}
                                    color={"#000"}
                                    className="ml-auto"
                                />
                            ) : (
                                <ChevronDown
                                    size={40}
                                    color={"#000"}
                                    className="ml-auto"
                                />
                            )}
                        </div>

                        <Collapse
                            className="viewDesktop six"
                            isOpen={selected === i}
                        >
                            <ul>
                                {phase.description.map((point, j) => (
                                    <li key={j}>{t(point)}</li>
                                ))}
                            </ul>
                        </Collapse>
                    </div>
                ))}
            </Carousel>

            <div className="viewPhone">
                {phases.map((phase, i) => (
                    <Collapse
                        key={i}
                        className="six mt-2 mx-auto col-md-8 col-lg-6 col-xl-4"
                        isOpen={selected === i}
                    >
                        <ul>
                            {phase.description.map((point, j) => (
                                <li key={j}>{t(point)}</li>
                            ))}
                        </ul>
                    </Collapse>
                ))}
            </div>
        </section>
    );
}

export default Support;
