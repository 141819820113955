import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import ClientPlantForm from "./ClientPlantForm";
import ClientPlant from "./ClientPlant";
import axios from "axios";
import Loading from "../../Loading";

function ClientPlantList(props) {
    const { species, clientId } = props;

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(0);
    const [activePlant, setActivePlant] = useState(0);
    const [editPlant, setEditPlant] = useState(0);
    const [serverError, setServerError] = useState("");

    const [plants, setPlants] = useState([]);
    useEffect(() => {
        setEditPlant(0);
        setLoading(true);

        axios(`/api/clients/${clientId}/plants`)
            .then(res => {
                setPlants(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }, [status]);

    function update() {
        setStatus(status + 1);
    }

    function selectPlant(id) {
        if (id === activePlant) {
            setActivePlant(0);
        } else {
            setActivePlant(id);
        }
    }

    return (
        <>
            <Alert
                color="danger"
                isOpen={serverError ? true : false}
                toggle={() => setServerError("")}
            >
                {serverError}
            </Alert>

            {loading ? (
                <Loading />
            ) : (
                plants.map((plant, i) =>
                    editPlant === plant.id ? (
                        <ClientPlantForm
                            key={plant.id}
                            setLoading={setLoading}
                            update={update}
                            clientId={clientId}
                            edit={plant.id}
                            defaults={{ name: plant.name }}
                            setServerError={setServerError}
                            setEditPlant={setEditPlant}
                        />
                    ) : (
                        <ClientPlant
                            key={plant.id}
                            plant={plant}
                            otherPlants={plants.toSpliced(i, 1)}
                            clientId={clientId}
                            species={species}
                            update={update}
                            activePlant={activePlant}
                            selectPlant={selectPlant}
                            setEditPlant={setEditPlant}
                        />
                    )
                )
            )}

            <ClientPlantForm
                setLoading={setLoading}
                update={update}
                clientId={clientId}
                setServerError={setServerError}
            />
        </>
    );
}

export default ClientPlantList;
