import React from "react";
import {
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
} from "react-feather";
import { Button } from "reactstrap";

function PaginationLinks(props) {
    const { meta, paginate, item } = props;

    return (
        <div className="center-xy flex-column">
            <div>
                <Button
                    color="link"
                    onClick={() => paginate("first")}
                    disabled={meta.current_page == 1}
                >
                    <ChevronsLeft />
                </Button>

                <Button
                    color="link"
                    onClick={() => paginate("prev")}
                    disabled={meta.current_page == 1}
                >
                    <ChevronLeft />
                </Button>

                <span className="p-3">{meta.current_page}</span>

                <Button
                    color="link"
                    onClick={() => paginate("next")}
                    disabled={meta.current_page == meta.last_page}
                >
                    <ChevronRight />
                </Button>

                <Button
                    color="link"
                    onClick={() => paginate("last")}
                    disabled={meta.current_page == meta.last_page}
                >
                    <ChevronsRight />
                </Button>
            </div>

            <small className="text-muted mt-1">
                Mostrando {item} {meta.from}-{meta.to} de {meta.total}
            </small>
        </div>
    );
}

export default PaginationLinks;
