import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import ItemList from "./ItemList";
import ItemImg from "./ItemImg";
import ItemContent from "./ItemContent";
import Loading from "../Loading";
import Header from "../home/header/Header";
import PhoneHeader from "../home/header/PhoneHeader";

function ProductsMain(props) {
    const { curItem, itemType } = props;
    const { t } = useTranslation();

    const [prod, setProd] = useState([]);
    const [species, setSpecies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/api/products")
            .then((res) => {
                setProd(res.data);
                return axios.get("/api/species");
            })
            .then((res) => {
                setSpecies(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <header>
                <Header />
                <PhoneHeader />
            </header>

            <main id="products" className="container py-5">
                <h2 className="page-title">{t("Productos")}</h2>

                <h3 className="one mb-4 text-center">{t(curItem.name)}</h3>

                <div className="row">
                    <div className="col-md-8 col-lg-4" id="itemContent">
                        <ItemContent item={curItem} itemType={itemType} />
                    </div>

                    <div className="col-md-4 col-lg-4">
                        <ItemImg image={curItem.img[0]} />
                    </div>

                    <nav className="col-lg-4" id="accordion">
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <ItemList
                                    category="Productos"
                                    items={prod}
                                    selected={curItem.name}
                                />

                                <ItemList
                                    category="Especies"
                                    items={species}
                                    selected={curItem.name}
                                />
                            </>
                        )}
                    </nav>
                </div>
            </main>
        </>
    );
}

const root = document.getElementById("products");

if (root) {
    const json = root.getAttribute("item");
    const curItem = JSON.parse(json);

    const type = root.getAttribute("type");

    ReactDOM.render(<ProductsMain curItem={curItem} itemType={type} />, root);
}
