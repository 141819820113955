import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Image } from "react-feather";

function SpeciesImg(props) {
    const [modal, setModal] = useState(false);
    const species = props.species;

    function toggle() {
        setModal(!modal);
    }

    return (
        <>
            <Button color="link" onClick={toggle}>
                <Image color="#3b6895" />
            </Button>
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle} className="text-capitalize">
                    {species.name} image
                </ModalHeader>
                <ModalBody className="mx-auto">
                    <img src={species.img} className="img-fluid" />
                </ModalBody>
            </Modal>
        </>
    );
}

export default SpeciesImg;
