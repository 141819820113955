import React from "react";
import { Parallax } from "react-scroll-parallax";

function Background(props) {
    const imgStyle = {
        // position: "absolute",
        width: 100 + "%",
        height: 100 + "%",
        paddingTop: 1500 + "px",
        zIndex: -1,
        opacity: 0.8,
    };

    const contentStyle = {
        position: "absolute",
        top: 0,
        width: 100 + "%",
        height: 100 + "%",
    };

    return (
        <div className="position-relative mb-5">
            <Parallax y={[-15, 15]}>
                <img src="/images/home/rayos.jpg" alt="" style={imgStyle}></img>
            </Parallax>
            <div style={contentStyle}>{props.children}</div>
        </div>
    );
}

export default Background;
