import React, { useState, useEffect } from "react";
import * as yup from "yup";
import axios from "axios";
import {
    Form,
    Alert,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Button,
    Spinner,
} from "reactstrap";
import { SwitchInput, CustomSelect, CustomDate } from "../../FormFields";
import DocumentFormTab from "./DocumentFormTab";
import ConfirmModal from "../../ConfirmModal";

function ReportForm(props) {
    const {
        setSending,
        setIsLoading,
        toggle,
        update,
        clientId,
        species,
        plants,
        seasons,
        edit,
        defaults,
    } = props;

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [serverMsg, setServerMsg] = useState("");
    const [notifyLoading, setNotifyLoading] = useState(false);
    const [serverError, setServerError] = useState("");
    const [confirmNotify, setConfirmNotify] = useState(false);

    const [activeTab, setActiveTab] = useState("0");
    function toggleTab(tab) {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const fields = {
        client: "",
        draft: true,
        species: "0",
        plant: "0",
        season: "0",
        date: new Date(),
        documents: Array(),
        images: Array(),
    };

    const emptyDoc = {
        type: "",
        file: "",
        comments: [],
        images: [],
        action: "create",
    };

    useEffect(() => {
        // console.log("init");
        let emptyFields = {};
        Object.keys(fields).forEach((key) => (emptyFields[key] = ""));
        setErrors(emptyFields);

        if (edit) {
            setValues({ ...defaults });
        } else {
            setValues({ ...fields, ["client"]: clientId });
        }
    }, []);

    const schema = yup.object().shape({
        client: yup
            .number("Formato incorrecto")
            .required("Seleccione un cliente, por favor.")
            .positive("Seleccione una opción válida, por favor.")
            .integer("Opción inválida."),
        draft: yup
            .boolean("Formato incorrecto")
            .required("Seleccione un estado, por favor."),
        species: yup
            .number("Formato incorrecto")
            .required("Seleccione una especie, por favor.")
            .positive("Seleccione una opción válida, por favor.")
            .integer("Opción inválida."),
        plant: yup
            .number("Formato incorrecto")
            .required("Seleccione una planta, por favor.")
            .positive("Seleccione una opción válida, por favor.")
            .integer("Opción inválida."),
        season: yup
            .number("Formato incorrecto")
            .required("Seleccione una temporada, por favor.")
            .positive("Seleccione una opción válida, por favor.")
            .integer("Opción inválida."),
        date: yup.date().required("Ingrese una fecha, por favor."),
        documents: yup
            .array()
            .required("Agregue al menos un documento, por favor.")
            .min(1, "Agregue al menos un documento, por favor."),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function addDoc() {
        let docBuff = values.documents;

        docBuff.push(emptyDoc);

        changeValue("documents", docBuff);
        setActiveTab(String(docBuff.length));
    }

    function updateDoc(docIndex, field, value) {
        let docBuff = values.documents;

        docBuff[docIndex] = { ...docBuff[docIndex], [field]: value };

        changeValue("documents", docBuff);
    }

    function rmDoc(docIndex) {
        let docBuff = values.documents;

        docBuff.splice(docIndex, 1);

        changeValue("documents", docBuff);
        setActiveTab(String(docBuff.length));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        let sendData = new FormData();

        for (let [key, value] of Object.entries(values)) {
            if (Array.isArray(value)) {
                value.forEach((document, index) => {
                    for (let [key, value] of Object.entries(document)) {
                        if (Array.isArray(value)) {
                            value.forEach((value, subIndex) => {
                                sendData.append(
                                    `documents[${index}][${key}][${subIndex}]`,
                                    value
                                );
                            });
                        } else {
                            sendData.append(
                                `documents[${index}][${key}]`,
                                typeof value === "boolean"
                                    ? Number(value)
                                    : value
                            );
                        }
                    }
                });
            } else {
                sendData.append(key, value);
            }
        }

        sendData.set("date", values.date.toISOString());
        sendData.set("draft", Number(values.draft));

        schema
            .validate(values, { abortEarly: false })
            .then(
                () =>
                    axios
                        .post(
                            "/api/reports" + (edit ? `/${edit}/update` : ""),
                            sendData,
                            {
                                headers: {
                                    "body-Type": "multipart/form-data",
                                },
                            }
                        )
                        .then((res) => {
                            console.log(res.data);
                            update();
                            setIsLoading(true);
                            setSending(false);
                            toggle();
                        })
                        .catch((err) => {
                            console.error(err);
                            setServerError(err.response.data.message);
                            setSending(false);
                        })
                // console.log(sendData.has("thumbnail"))
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setErrors({ ...errors, ...errBuff });
    }

    function notifyContacts() {
        setNotifyLoading(true);
        axios(`/api/reports/${edit}/notify`)
            .then(() => setServerMsg("Notificaciones enviadas (no es necesario guardar)"))
            .catch(err => setServerError(err.message))
            .finally(() => {
                setConfirmNotify(false);
                setNotifyLoading(false);
            });
    }

    return (
        <Form id="reportForm" onSubmit={handleSubmit}>
            <Nav tabs>
                <NavItem className="pointer">
                    <NavLink
                        className={
                            "form-tab" + (activeTab === "0" ? " active" : "")
                        }
                        onClick={() => toggleTab("0")}
                    >
                        Info
                    </NavLink>
                </NavItem>

                {values.documents &&
                    values.documents.map((document, index) => (
                        <NavItem
                            key={index}
                            className="pointer text-capitalize"
                        >
                            <NavLink
                                className={
                                    "form-tab" +
                                    (activeTab === String(index + 1)
                                        ? " active"
                                        : "")
                                }
                                onClick={() => toggleTab(String(index + 1))}
                            >
                                {document.type || "Nuevo Documento"}
                            </NavLink>
                        </NavItem>
                    ))}

                <NavItem className="pointer">
                    <NavLink className="form-tab" onClick={addDoc}>
                        Agregar Documento
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane className="pt-3" tabId="0">
                    <SwitchInput
                        value={values.draft || ""}
                        name="draft"
                        dName="Borrador"
                        outerDiv="d-flex justify-content-end"
                        touched={touched}
                        error={errors.draft}
                        changeValue={changeValue}
                        isRequired={isRequired}
                    />

                    <CustomSelect
                        value={values.species || ""}
                        name="species"
                        dName="especie"
                        isRequired={isRequired}
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        options={species}
                        touched={touched}
                        error={errors.species}
                        changeValue={changeValue}
                    />

                    <CustomSelect
                        value={values.plant || ""}
                        name="plant"
                        dName="planta"
                        isRequired={isRequired}
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        options={plants}
                        touched={touched}
                        error={errors.plant}
                        changeValue={changeValue}
                    />

                    <CustomSelect
                        value={values.season || ""}
                        name="season"
                        dName="temporada"
                        isRequired={isRequired}
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        options={seasons}
                        touched={touched}
                        error={errors.season}
                        changeValue={changeValue}
                    />

                    <CustomDate
                        value={values.date}
                        changeValue={changeValue}
                        name="date"
                        dName="fecha"
                        isRequired={isRequired}
                        outerDiv="row"
                        innerDiv="col-md-10"
                        labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                        inputClass="form-control"
                        touched={touched}
                        error={errors.date}
                        time
                    />
                </TabPane>

                {values.documents &&
                    values.documents.map((document, index) => (
                        <TabPane
                            key={index}
                            className="pt-3"
                            tabId={String(index + 1)}
                        >
                            <DocumentFormTab
                                document={document}
                                docIndex={index}
                                touched={touched}
                                updateDoc={updateDoc}
                                rmDoc={rmDoc}
                                edit={edit}
                            />
                        </TabPane>
                    ))}
            </TabContent>

            <div className="d-flex flex-column align-items-end">
                <p className="text-danger">*Campos obligatorios</p>
            </div>

            {edit && !defaults.draft && (
                <Button
                    color="info"
                    size="sm"
                    disabled={notifyLoading}
                    onClick={() => setConfirmNotify(true)}
                >
                    Reenviar notificaciones
                </Button>
            )}

            <Alert
                color="danger"
                isOpen={serverError ? true : false}
                toggle={() => setServerError("")}
                className="mt-3"
            >
                {serverError}
            </Alert>
            <Alert
                color="success"
                isOpen={serverMsg ? true : false}
                toggle={() => setServerMsg("")}
                className="mt-3"
            >
                {serverMsg}
            </Alert>

            <ConfirmModal
                isOpen={confirmNotify}
                toggle={() => setConfirmNotify(false)}
                action={notifyContacts}
                label="Enviar"
                loading={notifyLoading}
                color="info"
            >
                ¿Realmente desea reenviar notificaciones a todos los contactos de esta planta?
            </ConfirmModal>
        </Form>
    );
}

export default ReportForm;
