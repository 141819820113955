import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import ClientContactForm from "./ClientContactForm";
import ClientContact from "./ClientContact";
import axios from "axios";

function ClientContactList(props) {
    const { species, clientId, plantId, otherPlants } = props;

    const [editContact, setEditContact] = useState(0);
    const [serverError, setServerError] = useState("");
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(true);

    const [contacts, setContacts] = useState([]);
    useEffect(() => {
        setEditContact(0);
        setLoading(true);

        axios(`/api/clients/${clientId}/contacts?plant=${plantId}`)
            .then(res => {
                setContacts(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setServerError(err.message);
                setLoading(false);
            });
    }, [status]);

    function update() {
        setStatus(status + 1);
    }

    return (
        <>
            <Alert
                color="danger"
                isOpen={serverError ? true : false}
                toggle={() => setServerError("")}
            >
                {serverError}
            </Alert>

            <table className="table table-light table-sm mb-1">
                <thead>
                    <tr className="table-primary">
                        <th scope="col">Especie</th>
                        <th scope="col">Correo</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>

                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan="3" className="text-center">
                                Cargando...
                            </td>
                        </tr>
                    ) : (
                        <>
                            {contacts.map((contact, i) =>
                                editContact == contact.pivot ? (
                                    <ClientContactForm
                                        key={i}
                                        setIsLoading={setLoading}
                                        update={update}
                                        contact={contact}
                                        clientId={clientId}
                                        plantId={plantId}
                                        species={species}
                                        setServerError={setServerError}
                                        setEditContact={setEditContact}
                                    />
                                ) : (
                                    <ClientContact
                                        key={i}
                                        contact={contact}
                                        otherPlants={otherPlants}
                                        update={update}
                                        setIsLoading={setLoading}
                                        setEditContact={setEditContact}
                                    />
                                )
                            )}

                            <ClientContactForm
                                setIsLoading={setLoading}
                                update={update}
                                clientId={clientId}
                                plantId={plantId}
                                species={species}
                                setServerError={setServerError}
                            />
                        </>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default ClientContactList;
