import React, { useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";
// import { BSInput, BSSelect } from "../../Fields";
import * as Yup from "yup";
import axios from "axios";

function EditSpecies(props) {
    const { species, isOpen, toggle, update } = props;
    const formRef = useRef();

    function submitForm() {
        formRef.current.handleSubmit();
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Edit Species</ModalHeader>
            <ModalBody>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        name: species.name,
                        category: species.category,
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .max(50, "Must be 50 characters or less")
                            .required("Required"),
                        category: Yup.string()
                            .oneOf(["fruit", "vegetable"], "Invalid category")
                            .required("Required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        axios
                            .put("/api/species/" + species.id, values)
                            .then((res) => {
                                console.log(res);
                                setSubmitting(false);
                                update();
                                toggle();
                            })
                            .catch((err) => console.error(err));
                    }}
                >
                    <Form>
                        {/* <BSInput label="Name" name="name" type="text" />
                        <BSSelect
                            label="Category"
                            name="category"
                            options={["fruit", "vegetable"]}
                        /> */}
                    </Form>
                </Formik>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>{" "}
                <Button color="primary" onClick={submitForm}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditSpecies;
