import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import axios from "axios";
import { Form, Button, Spinner } from "reactstrap";
import { CustomInput, CustomSelect } from "../../FormFields";
import { countries } from "./Countries";
import HCaptcha from "@hcaptcha/react-hcaptcha";

function ContactForm(props) {
    const { t, i18n } = useTranslation();
    const { setSent } = props;

    const captchaRef = useRef(null);

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const fields = {
        name: "",
        email: "",
        country: "",
        phone: "",
        message: "",
        captcha: "",
    };

    useEffect(() => {
        // console.log("init");
        setValues({ ...fields, country: "0" });
        setErrors(fields);
    }, []);

    const schema = yup.object().shape({
        name: yup.string().required("Por favor, ingrese su nombre"),
        email: yup.string().required("Por favor, ingrese su correo electrónico").email("Correo electrónico inválido"),
        country: yup
            .string()
            .required()
            .oneOf(countries.map((country) => country.id)),
        phone: yup.string().required("Por favor, ingrese su número de contacto").max(20),
        message: yup.string().required("Por favor, escriba un mensaje").max(63206),
        captcha: yup.string().required("Por favor, complete el captcha"),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);
        setTouched(true);

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios
                    .post("/contact", values)
                    .then((res) => {
                        console.log(res);
                        setSent(true);
                    })
                    .catch((err) => console.error(err))
            )
            .catch((err) => setErrMsgs(err));

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(err) {
        // console.log(`msgs: ${msgs}`);
        console.error(err);

        let errBuff = {};

        err.errors.forEach((msg) => {
            const field = msg.substring(0, msg.indexOf(" "));
            // console.log(`field: ${field}`);
            // console.log(`msg: ${msg}`);
            errBuff[field] = msg;
        });
        // console.log(`errBuff: ${errBuff}`);

        setErrors({ ...errors, ...errBuff });
        setLoading(false);
    }

    function onExpire() {
        captchaRef.current.resetCaptcha();
        setValues({ ...values, captcha: "" });
        setErrors({ ...errors, captcha: "Captcha expirado" });
        setTouched(true);
    }

    return (
        <Form className="mx-auto col-md-10 mt-5 mb-5" onSubmit={handleSubmit}>
            <CustomInput
                value={values.name || ""}
                type="text"
                name="name"
                dName={t("nombre")}
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                touched={touched}
                error={errors.name}
                changeValue={changeValue}
            />

            <CustomInput
                value={values.email || ""}
                type="email"
                name="email"
                dName={t("correo electrónico")}
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                touched={touched}
                error={errors.email}
                changeValue={changeValue}
            />

            <CustomSelect
                value={values.country || ""}
                name="country"
                dName={t("país")}
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                options={countries}
                touched={touched}
                error={errors.country}
                changeValue={changeValue}
            />

            <CustomInput
                value={values.phone || ""}
                type="text"
                name="phone"
                dName={t("teléfono")}
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                touched={touched}
                error={errors.phone}
                changeValue={changeValue}
            />

            <CustomInput
                value={values.message || ""}
                type="textarea"
                name="message"
                dName={t("mensaje")}
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                touched={touched}
                error={errors.message}
                changeValue={changeValue}
            />

            <div className="row form-group">
                <div className="col text-right">
                    <HCaptcha
                        sitekey="80d43ec8-a4c8-4fbc-9296-efd23cfe6964"
                        onVerify={(token) => changeValue("captcha", token)}
                        onError={(err) => setErrors({ ...errors, captcha: err })}
                        onExpire={onExpire}
                        ref={captchaRef}
                    />

                    {errors.captcha && <small className="text-danger">{errors.captcha}</small>}
                </div>
            </div>

            <div className="d-flex flex-column align-items-end pt-2">
                <p className="text-danger">*{t("Campos obligatorios")}</p>

                {loading ? (
                    <Spinner color="primary" size="sm" />
                ) : (
                    <Button color="primary">{t("Enviar")}</Button>
                )}
            </div>
        </Form>
    );
}

export default ContactForm;
