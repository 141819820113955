import React from "react";

function Lang(props) {
    const { divClass } = props;

    function getUrl(lang) {
        const protocol = window.location.protocol;
        let hostname = window.location.hostname.split(".");
        hostname = `${hostname.at(-2)}.${hostname.at(-1)}`;

        return `${protocol}//${lang}.${hostname}`;
    }

    return (
        <div id="lang" className={divClass}>
            <a href={getUrl("es")}>
                <img
                    className="mx-2 pointer"
                    src="/images/lang/es.png"
                    height="25"
                    width="25"
                    alt="español"
                />
            </a>

            <a href={getUrl("en")}>
                <img
                    className="mx-2 pointer"
                    src="/images/lang/en.png"
                    height="25"
                    width="25"
                    alt="english"
                />
            </a>

            <a href={getUrl("zh")}>
                <img
                    className="mx-2 pointer"
                    src="/images/lang/ch.png"
                    height="25"
                    width="25"
                    alt="中文"
                />
            </a>
        </div>
    );
}

export default Lang;
