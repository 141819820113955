const indicators = [
    {
        id: "logins",
        name: "Inicios de sesión",
    },
    {
        id: "visits",
        name: "Ingresos a Área de Clientes",
    },
    {
        id: "reportViews",
        name: "Reportes vistos",
    },
    {
        id: "reportDownloads",
        name: "Reportes descargados",
    },
];

const dateModes = [
    {
        id: "all",
        name: "Todos los tiempos",
    },
    {
        id: "exact",
        name: "Fecha exacta",
    },
    {
        id: "range",
        name: "Rango",
    },
];

const weekDays = [
    { id: 1, name: "Lunes" },
    { id: 2, name: "Martes" },
    { id: 3, name: "Miércoles" },
    { id: 4, name: "Jueves" },
    { id: 5, name: "Viernes" },
    { id: 6, name: "Sábado" },
    { id: 7, name: "Domingo" },
];

const months = [
    { id: 1, name: "enero" },
    { id: 2, name: "febrero" },
    { id: 3, name: "marzo" },
    { id: 4, name: "abril" },
    { id: 5, name: "mayo" },
    { id: 6, name: "junio" },
    { id: 7, name: "julio" },
    { id: 8, name: "agosto" },
    { id: 9, name: "septiembre" },
    { id: 10, name: "octubre" },
    { id: 11, name: "noviembre" },
    { id: 12, name: "diciembre" },
];

const timeFilters = [
    {
        id: "dayOfWeek",
        name: "Día de la semana",
        place: 0,
        type: "select",
        options: weekDays,
        default: 1,
    },
    {
        id: "dayOfMonth",
        name: "Día del mes",
        place: 1,
        type: "number",
        default: 1,
    },
    {
        id: "dayOfQuarter",
        name: "Día del cuarto",
        place: 2,
        type: "number",
        default: 1,
    },
    {
        id: "dayOfYear",
        name: "Día del año",
        place: 3,
        type: "number",
        default: 1,
    },
    {
        id: "weekOfMonth",
        name: "Semana del mes",
        place: 4,
        type: "number",
        default: 1,
    },
    {
        id: "weekOfYear",
        name: "Semana del año",
        place: 5,
        type: "number",
        default: 1,
    },
    {
        id: "monthActual",
        name: "Mes",
        place: 6,
        type: "select",
        options: months,
        default: 1
    },
    {
        id: "quarterActual",
        name: "Cuarto",
        place: 7,
        type: "number",
        default: 1,
    },
    {
        id: "yearActual",
        name: "Año",
        place: 8,
        type: "number",
        default: 2022,
    },
];

const dictionary = {
    logins: "Inicios de sesión",
    visits: "Ingresos a Área de Clientes",
    reportViews: "Reportes vistos",
    reportDownloads: "Reportes descargados",
    dayOfWeek: "día de la semana",
    dayOfMonth: "día del mes",
    dayOfQuarter: "día del cuarto",
    dayOfYear: "día del año",
    weekOfMonth: "semana del mes",
    weekOfYear: "semana del año",
    monthActual: "mes",
    quarterActual: "cuarto",
    yearActual: "año",
    loginMethod: "inicio de sesión por",
    client: "cliente",
    species: "especie",
    season: "temporada",
};

export default {
    indicators,
    dateModes,
    timeFilters,
    dictionary
};