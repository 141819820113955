import React from "react";
import { Spinner } from "reactstrap";

function Loading(props) {
    const { color, size, compact } = props;

    return (
        <div className={"center-xy" + (compact ? "" : " p-5")}>
            <Spinner color={color ?? "primary"} size={size ?? "md"} />
        </div>
    );
}

export default Loading;
