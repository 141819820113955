import React from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";

function FormModal(props) {
    const { title, modal, toggle, submit, children, isLoading, form, button } =
        props;

    return (
        <>
            {button ? (
                <Button
                    outline
                    color="primary"
                    onClick={toggle}
                    className="float-right btn-sqr"
                >
                    {title}
                </Button>
            ) : (
                ""
            )}

            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle} className="bg-primary text-white">
                    {title}
                </ModalHeader>

                <ModalBody>{children}</ModalBody>

                <ModalFooter className="bg-light">
                    <Button
                        color="secondary"
                        onClick={toggle}
                        className="btn-sqr"
                    >
                        Cerrar
                    </Button>{" "}
                    <Button
                        color="primary"
                        type="submit"
                        form={form}
                        className="btn-sqr"
                    >
                        {isLoading ? (
                            <div className="center-xy">
                                <Spinner color="white" size="sm" />
                            </div>
                        ) : (
                            submit
                        )}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default FormModal;
