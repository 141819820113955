import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useController } from "react-scroll-parallax";
import { Spinner } from "reactstrap";
import ItemList from "./ItemList";
import ItemImg from "./ItemImg";
import ItemContent from "./ItemContent";
import Loading from "../../Loading";

function Products(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    const [prod, setProd] = useState([]);
    const [species, setSpecies] = useState([]);
    const [curItem, setCurItem] = useState({});
    const [itemType, setItemType] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/api/products")
            .then((res) => {
                setProd(res.data);
                setCurItem(res.data[0]);
                return axios.get("/api/species");
            })
            .then((res) => {
                setSpecies(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    function findItem(name) {
        let item = prod.find((product) => product.name == name);

        if (item == undefined) {
            item = species.find((species) => species.name == name);
            setItemType("species");
        } else {
            setItemType("product");
        }

        setCurItem(item);
    }

    const { parallaxController } = useController();
    useEffect(() => parallaxController.update());

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Productos")}</h2>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="row">
                    <nav className="col-lg-4" id="accordion">
                        <ItemList
                            category={t("Productos")}
                            items={prod}
                            selectItem={findItem}
                            selected={curItem.name}
                        />
                        <ItemList
                            category={t("Especies")}
                            items={species}
                            selectItem={findItem}
                            selected={curItem.name}
                        />
                    </nav>
                    <div className="col-md-4 col-lg-4">
                        <ItemImg image={curItem.img[0]} />
                    </div>
                    <div className="col-md-8 col-lg-4" id="itemContent">
                        <ItemContent
                            item={curItem}
                            itemType={itemType}
                            selectItem={findItem}
                        />
                    </div>
                </div>
            )}
        </section>
    );
}

export default Products;
