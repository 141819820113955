import React from "react";
import { useTranslation } from "react-i18next";

function ProductStages(props) {
    const { t, i18n } = useTranslation();
    const { stages } = props;

    const set = [
        "cosecha",
        "espera a proceso",
        "transporte marítimo o aéreo",
        "guarda",
    ];

    return (
        <>
            <h4 className="four mb-3">{t("Usos según etapa de la cadena")}</h4>

            <svg viewBox="0 0 200 16" className="w-100">
                <line
                    x1="0"
                    x2="200"
                    y1="8"
                    y2="8"
                    stroke="#8dcde5"
                    strokeWidth="2"
                />

                {set.map((stage, i) => (
                    <circle
                        key={i}
                        cx={25 + 50 * i}
                        cy="8"
                        r="6"
                        strokeWidth="2"
                        className={
                            "stage" +
                            (stages.includes(stage.split(" ")[0])
                                ? "-active"
                                : "-inactive")
                        }
                    />
                ))}
            </svg>

            <div className="row mx-0 text-center">
                {set.map((stage, i) => (
                    <p
                        key={i}
                        className={
                            "col p-2 stage" +
                            (stages.includes(stage.split(" ")[0])
                                ? "-active"
                                : "-inactive")
                        }
                    >
                        {stage}
                    </p>
                ))}
            </div>
        </>
    );
}

export default ProductStages;
