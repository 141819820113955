import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Button, Spinner } from "reactstrap";
import ArticleList from "./ArticleList";
import PaginationLinks from "../../PaginationLinks";

function ArticlesMain() {
    const [status, setStatus] = useState(0);
    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/api/articles")
            .then((res) => {
                setArticles(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }, [status]);

    function update() {
        setStatus(status + 1);
    }

    function paginate(direction) {
        setIsLoading(true);

        axios
            .get(articles.links[direction])
            .then((res) => {
                setArticles(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }

    return (
        <div className="container">
            <div className="row justify-content-center mb-5">
                <div className="col-md-8">
                    <h1 className="d-inline nine text-dark text-uppercase">
                        Gestor de noticias
                    </h1>

                    <a href="/admin/editor">
                        <Button
                            outline
                            color="primary"
                            className="float-right btn-sqr"
                        >
                            Nueva noticia
                        </Button>
                    </a>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    {isLoading ? (
                        <div className="center-xy p-5">
                            <Spinner color="primary" />
                        </div>
                    ) : (
                        <div>
                            <ArticleList
                                articles={articles.data}
                                setIsLoading={setIsLoading}
                                update={update}
                            />
                            <PaginationLinks
                                className="mt-3"
                                meta={articles.meta}
                                paginate={paginate}
                                item="noticias"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

const root = document.getElementById("articlesAdmin");
if (root) {
    ReactDOM.render(<ArticlesMain />, root);
}
