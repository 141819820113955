import { React, useEffect } from "react";
import ReactDOM from "react-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { I18nextProvider } from "react-i18next";
import i18nConf from "../../i18n";
import i18n from "../../i18n";

import Header from "./header/Header";
import PhoneHeader from "./header/PhoneHeader";
import Slider from "./slider/Slider";
import Title from "./Title";
import ToTop from "./ToTop";
import Technology from "./Technology";
import Background from "./Background";
import Products from "./products/Products";
import Sustainable from "./sustainable/Sustainable";
import Support from "./Support";
import About from "./about/About";
import News from "./news/News";
import Trust from "./trust/Trust";
import Contact from "./contact/Contact";
import QualityPolicy from "./QualityPolicy";
import Footer from "./Footer";

function Home(props) {
    const { lang } = props;

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, []);

    return (
        <I18nextProvider i18n={i18nConf}>
            <ParallaxProvider>
                <Background>
                    <header>
                        <PhoneHeader />
                        <Header />
                        <Title />
                        <Slider />
                    </header>

                    <main className="container">
                        <Products id="productos" className="mt-5" />
                        <Technology id="tecnologia" className="mt-5" />
                        <Sustainable id="sustentabilidad" className="mt-5" />
                        <Support id="asesoria" className="mt-5" />
                        <About id="nosotros" className="mt-5" />
                        <News id="noticias" className="mt-5" />
                        <Trust id="confienza" className="mt-5" />
                        <QualityPolicy />
                        <Contact id="contacto" className="mt-5 pb-5" />
                    </main>

                    <Footer />

                    <ToTop />
                </Background>
            </ParallaxProvider>
        </I18nextProvider>
    );
}

const root = document.getElementById("home");
if (root) {
    const lang = root.getAttribute("lang");
    ReactDOM.render(<Home lang={lang} />, root);
}
