import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, Navbar, NavItem, NavLink } from "reactstrap";
import Lang from "../Lang";

function Header() {
    const { t, i18n } = useTranslation();

    return (
        <div className="viewDesktop">
            <Lang divClass="center-xy mt-2" />

            <Navbar className="container">
                <Nav className="headerCol">
                    <NavItem>
                        <NavLink className="menuLink" href="/#tecnologia">
                            {t("Tecnología")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/products">
                            {t("Productos")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/#sustentabilidad">
                            {t("Sustentabilidad")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/#asesoria">
                            {t("Asesoría")}
                        </NavLink>
                    </NavItem>
                </Nav>

                <a href="/">
                    <img
                        id="logoHeader"
                        src="/images/logo.gif"
                        alt="logo Paclife"
                    />
                </a>

                <Nav className="headerCol">
                    <NavItem>
                        <NavLink className="menuLink" href="/#nosotros">
                            {t("Nosotros")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/blog">
                            {t("Noticias")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/#contacto">
                            {t("Contacto")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className="menuLink"
                            id="accesoClientes"
                            href="/login"
                        >
                            {t("Acceso Clientes")}
                        </NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    );
}

export default Header;
