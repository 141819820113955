import React, { useState, useEffect } from "react";
import * as yup from "yup";
import axios from "axios";
import { Form, Alert } from "reactstrap";
import { CustomInput } from "../../FormFields";

function ClientForm(props) {
    const { formId, setSending, setIsLoading, toggle, update, edit, defaults } =
        props;

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState("");

    const fields = {
        user: "",
        name: "",
        email: "",
        phone: "",
        password: "",
    };

    useEffect(() => {
        // console.log("init");
        setValues(defaults ?? fields);

        let emptyFields = {};
        Object.keys(fields).forEach((key) => (emptyFields[key] = ""));
        setErrors(emptyFields);
    }, []);

    const schema = yup.object().shape({
        name: yup.string().required("ingrese un nombre por favor").max(64),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios({
                    method: edit ? "put" : "post",
                    url: "/api/clients" + (edit ? `/${edit}` : ""),
                    data: values,
                })
                    .then((res) => {
                        console.log(res);
                        update();
                        setIsLoading(true);
                        setSending(false);
                        toggle();
                    })
                    .catch((err) => {
                        console.error(err);
                        setServerError(err.response.data.message);
                        setSending(false);
                    })
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setErrors({ ...errors, ...errBuff });
    }

    return (
        <Form id={formId} onSubmit={handleSubmit}>
            <CustomInput
                value={values.name || ""}
                type="text"
                name="name"
                dName="Nombre"
                isRequired={isRequired}
                outerDiv="form-group row"
                innerDiv="col-md-7"
                labelClass="col-md-4 col-form-label text-md-right"
                touched={touched}
                error={errors.name}
                changeValue={changeValue}
            />

            <div className="d-flex flex-column align-items-end">
                <p className="text-danger">*Campos obligatorios</p>
            </div>

            <Alert
                color="danger"
                isOpen={serverError ? true : false}
                toggle={() => setServerError("")}
                className="mt-3"
            >
                {serverError}
            </Alert>
        </Form>
    );
}

export default ClientForm;
