import React, { useState } from "react";
import Roles from "./Roles";
import { Spinner, Button } from "reactstrap";
import { Edit, XCircle } from "react-feather";

function User(props) {
    const { user, update, selectUser, setIsLoading } = props;

    const [deleting, setDeleting] = useState(false);

    function displayPhone(phone) {
        if (phone) {
            return <li className="list-group-item">Teléfono: {phone}</li>;
        } else {
            return "";
        }
    }

    function deleteUser() {
        setDeleting(true);

        if (confirm(`¿Realmente quiere eliminar a ${user.user} del sistema?`)) {
            axios
                .delete("/api/users/" + user.id)
                .then((res) => {
                    console.log(res);
                    setDeleting(false);
                    update();
                    setIsLoading(true);
                })
                .catch((err) => {
                    console.error(err);
                    alert(err.response.data);
                    setDeleting(false);
                });
        } else {
            setDeleting(false);
        }
    }

    function formatDate(isoDate) {
        return new Date(Date.parse(isoDate)).toLocaleString("es-CL");
    }

    return (
        <div className="card">
            <div className="card-header" id={"heading" + user.id}>
                <div className="row">
                    <div className="col-1 my-auto">
                        <h4 className="text-center text-muted">{user.id}</h4>
                    </div>

                    <div className="col">
                        <h4>
                            <a
                                className="card-link"
                                data-toggle="collapse"
                                href={"#collapse" + user.id}
                                aria-expanded="false"
                                aria-controls={"collapse" + user.id}
                            >
                                {user.name || user.email}
                            </a>
                        </h4>

                        <div>
                            <Roles roles={user.roles} />
                        </div>
                    </div>

                    <div className="col-sm-3 d-flex align-items-end justify-content-end">
                        <Button
                            color="link"
                            onClick={() => selectUser(user.id)}
                        >
                            <Edit color="#ffc107" />
                        </Button>
                        <Button color="link" onClick={deleteUser}>
                            {deleting ? (
                                <div className="center-xy">
                                    <Spinner color="danger" size="sm" />
                                </div>
                            ) : (
                                <XCircle color="#dc3545" />
                            )}
                        </Button>
                    </div>
                </div>
            </div>

            <div
                id={"collapse" + user.id}
                className="collapse"
                aria-labelledby={"heading" + user.id}
                data-parent="#accordionParent"
            >
                <div className="card-body p-1">
                    <ul className="list-group-flush m-0">
                        <li className="list-group-item">
                            Usuario: {user.user}
                        </li>
                        <li className="list-group-item">Email: {user.email}</li>
                        {displayPhone(user.phone)}
                        <li className="list-group-item">
                            Fecha de registro: {formatDate(user.created_at)}
                        </li>
                        <li className="list-group-item">
                            Última actualización: {formatDate(user.updated_at)}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default User;
