import React, { useState } from "react";
import Loading from "../Loading";
import ClientList from "./clients/ClientList";
import ClientSpeciesList from "./clients/ClientSpeciesList";

function SideMenu(props) {
    const {
        isClient,
        isLoading,
        user,
        curClient,
        setCurClient,
        curSpecies,
        setCurSpecies,
        clients,
        resetFilters,
    } = props;

    const [menuOut, setMenuOut] = useState(true);

    function toggleMenu() {
        setMenuOut(!menuOut);
    }

    function selectClient(client) {
        resetFilters();
        setCurClient(client);
        toggleMenu();
    }

    return (
        <div
            id="sidemenu"
            style={{ left: menuOut ? 0 : "-92%" }}
            className={isClient ? "hidden-mobile-sidemenu" : ""}
        >
            <div id="sidemenu-list">
                <div className="title mb-4">
                    <h3 className="one text-white">
                        {isClient ? "Especies" : "Clientes"}
                    </h3>
                </div>

                {isLoading ? (
                    <Loading color="white" />
                ) : isClient ? (
                    <ClientSpeciesList
                        user={user}
                        curSpecies={curSpecies}
                        setCurSpecies={setCurSpecies}
                    />
                ) : (
                    <ClientList
                        curClient={curClient}
                        selectClient={selectClient}
                        clients={clients}
                    />
                )}
            </div>

            <div
                id="sidemenu-handle"
                onClick={toggleMenu}
                style={{ height: menuOut ? "100%" : "min-content" }}
            >
                {!menuOut && <span className="mb-2">CLIENTES</span>}
                {String.fromCharCode(menuOut ? 11207 : 11208)}
            </div>
        </div>
    );
}

export default SideMenu;
