import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Edit, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import ConfirmModal from "../../ConfirmModal";
import { Home } from "react-feather";
import ContactMoveForm from "./ContactMoveForm";

function ClientContact(props) {
    const { contact, otherPlants, update, setIsLoading, setEditContact } = props;

    const [deleting, setDeleting] = useState(false);
    const [plantSelect, setPlantSelect] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);
    function toggleConfirmDelete() {
        setConfirmDelete(!confirmDelete);
    }

    function deleteContact() {
        setDeleting(true);

        axios
            .delete(`/api/contacts/${contact.pivot}`)
            .then((res) => {
                console.log(res);
                setDeleting(false);
                update();
                setIsLoading(true);
            })
            .catch((err) => {
                console.error(err);
                alert(err.response.data);
                setDeleting(false);
            });
    }

    return (
        <>
            <tr>
                <td>{contact.species_name}</td>

                <td>{contact.email}</td>

                <td>
                    <span>
                        <Button
                            color="link"
                            onClick={() => setPlantSelect(!plantSelect)}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttPlantSelect"
                        >
                            <Home color={plantSelect ? "grey" : "#3b6895"} size="24" />
                        </Button>

                        <ReactTooltip id="ttPlantSelect" effect="solid">
                            {plantSelect ? "Cancelar" : "Cambiar de planta"}
                        </ReactTooltip>
                    </span>

                    <span>
                        <Button
                            color="link"
                            onClick={() => setEditContact(contact.pivot)}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttEditContact"
                        >
                            <Edit color="#ffc107" size="24" />
                        </Button>

                        <ReactTooltip id="ttEditContact" effect="solid">
                            Editar contacto
                        </ReactTooltip>
                    </span>

                    <span>
                        <Button
                            color="link"
                            onClick={toggleConfirmDelete}
                            className="px-1 py-0"
                            data-tip
                            data-for="ttDeleteContact"
                        >
                            <XCircle color="#dc3545" size="24" />
                        </Button>

                        <ReactTooltip id="ttDeleteContact" effect="solid">
                            Eliminar contacto
                        </ReactTooltip>

                        <ConfirmModal
                            isOpen={confirmDelete}
                            toggle={toggleConfirmDelete}
                            action={deleteContact}
                            label="Eliminar"
                            loading={deleting}
                        >
                            ¿Realmente desea eliminar el contacto{" "}
                            <strong>{contact.email}</strong>?
                        </ConfirmModal>
                    </span>
                </td>
            </tr>

            {plantSelect && (
                <tr>
                    <td colSpan={2} className="pt-0" />

                    <td className="pt-0">
                        <ContactMoveForm
                            pivot={contact.pivot}
                            otherPlants={otherPlants}
                            update={update}
                        />
                    </td>
                </tr>
            )}
        </>
    );
}

export default ClientContact;
