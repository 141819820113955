import React from "react";
import { useTranslation } from "react-i18next";
import AboutCarousel from "./AboutCarousel";

function About(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Nosotros")}</h2>
            <div className="row">
                <div className="col-lg-4">
                    <h3 className="three mb-5">{t("Quiénes somos")}</h3>
                    <p className="seven text-justify">
                        {t(
                            "Somos una empresa de amplia trayectoria y experiencia, con bases familiares, que busca siempre la evolución y el desarrollo en la incorporación de nuevas tecnologías en cada uno de sus productos. Aportamos soluciones efectivas, seguras y estables de embalaje en la conservación y exportación de productos hortofrutícolas en todo el mundo."
                        )}
                    </p>
                    <p className="seven text-justify">
                        {t(
                            "Nuestros envases y tecnología son los únicos envases patentados del mercado (PAT. N° 50344) que dan cuenta de atributos y aditivos activos que reaccionan y aportan a la preservación, conservación y transporte SEGURO de fruta fresca."
                        )}
                    </p>
                    <p className="seven text-justify">
                        {t(
                            "Envases confeccionados con materias primas y aditivos vírgenes (100% reciclables), bajo estrictas normas de producción y calidad."
                        )}
                    </p>
                </div>

                <div className="col-lg-8">
                    <h3 className="three mb-5">{t("Dónde estamos")}</h3>
                    <AboutCarousel />
                </div>
            </div>
        </section>
    );
}

export default About;
