import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import Lang from "../Lang";

function PhoneHeader() {
    const { t, i18n } = useTranslation();

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
        <Navbar className="viewPhone container" color="faded" light>
            <NavbarToggler onClick={toggleNavbar} />

            <NavbarBrand href="/" className="center-block">
                <img
                    className="d-block mx-auto"
                    src="/images/logo.gif"
                    height="125"
                    width="125"
                    alt="logo Paclife"
                />
            </NavbarBrand>

            <Collapse isOpen={!collapsed} navbar className="pb-3">
                <Nav navbar>
                    <NavItem>
                        <NavLink href="/#tecnologia" className="menuLink">
                            {t("Tecnología")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/products" className="menuLink">
                            {t("Productos")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/#sustentabilidad" className="menuLink">
                            {t("Sustentabilidad")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/#asesoria" className="menuLink">
                            {t("Asesoría")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/#nosotros" className="menuLink">
                            {t("Nosotros")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/blog" className="menuLink">
                            {t("Noticias")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink href="/#contacto" className="menuLink">
                            {t("Contacto")}
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink className="menuLink" href="/login">
                            {t("Acceso Clientes")}
                        </NavLink>
                    </NavItem>
                </Nav>

                <Lang divClass="mt-3 d-flex" />
            </Collapse>
        </Navbar>
    );
}

export default PhoneHeader;
