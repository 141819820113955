import React from "react";

function AnalyticsResult(props) {
    const { result } = props;

    return <div>
        <p>Total: {result.total}</p>

        {result.total > result.per_page && <small>Mostrando los últimos {result.per_page}</small>}

        <table className="table">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th>Cliente</th>
                </tr>
            </thead>

            <tbody>
                {result.data.map((entry, i) => (
                    <tr key={i}>
                        {Object.values(entry).map((value, i) => (
                            <td key={i}>{value}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>;
}

export default AnalyticsResult;