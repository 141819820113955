import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";

function ConfirmModal(props) {
    const { isOpen, toggle, action, label, loading, color = "danger" } = props;

    function toggleOrNot() {
        if (!loading) {
            toggle();
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleOrNot} centered>
            <ModalHeader>Confirmación</ModalHeader>

            <ModalBody className="text-center">{props.children}</ModalBody>

            <ModalFooter className="d-flex justify-content-end align-items-center">
                <Button color="secondary" className="btn-sqr" onClick={toggle} disabled={loading}>
                    Cancelar
                </Button>

                {loading ? (
                    <div className="px-2">
                        <Spinner color={color} size="sm" />
                    </div>
                ) : (
                    <Button color={color} className="btn-sqr" onClick={action}>
                        {label}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmModal;
