import React, { useState, useEffect } from "react";
import axios from "axios";
import AddedTag from "./AddedTag";

function TagInput(props) {
    const { tagArray, update } = props;

    const [curTag, setCurTag] = useState("");

    const [storedTags, setStoredTags] = useState([]);
    useEffect(() => {
        axios("/api/tags")
            .then((res) => setStoredTags(res.data))
            .catch((err) => console.error(err));
    }, []);

    function addTag(event) {
        if (event.key === "Enter" || event.key === ",") {
            event.preventDefault();
            update("tags", [...tagArray, curTag.trim()]);
            setCurTag("");
        }
    }

    function rmTag(index) {
        const tagBuff = tagArray;
        tagBuff.splice(index, 1);
        update(tagBuff);
    }

    return (
        <div className="p-1 d-flex align-items-center flex-wrap border">
            {tagArray.map((tag, i) => (
                <AddedTag key={i} tag={tag} index={i} rmTag={rmTag} />
            ))}

            <input
                type="search"
                name="tag"
                value={curTag}
                id="tagInput"
                list="tagList"
                className="input-reset flex-grow-1"
                onChange={(e) => setCurTag(e.target.value)}
                onKeyDown={addTag}
            />

            <datalist id="tagList">
                {storedTags.map((tag, i) => (
                    <option key={i} value={tag.name} />
                ))}
            </datalist>
        </div>
    );
}

export default TagInput;
