import React from "react";
import { useTranslation } from "react-i18next";

function RelatedItems(props) {
    const { t, i18n } = useTranslation();
    const { item } = props;

    return (
        <>
            <h4 className="four mb-3">
                {t("Tecnologías disponibles para ") + item.name}
            </h4>

            <div id="relatedContent">
                {item.related.map((related, i) => (
                    <span key={i}>
                        {i > 0 && "| "}
                        <a href={"/products/" + related.slug}>
                            {t(related.name)}
                        </a>{" "}
                    </span>
                ))}
            </div>
        </>
    );
}

export default RelatedItems;
