import React, { useState } from "react";
import ReactDOM from "react-dom";
import Loading from "../../Loading";
import AnalyticsForm from "./AnalyticsForm";
import AnalyticsResult from "./AnalyticsResult";

function AnalyticsMain() {
    const [result, setResult] = useState({ ok: false });
    const [sending, setSending] = useState(false);

    /* function paginate(direction) {
        setSending(true);

        axios
            .get(result.links[direction])
            .then((res) => {
                setResult({
                    ok: true,
                    data: res.data,
                });
                setSending(false);
            })
            .catch((err) => {
                setResult({ ok: false });
                console.error(err);
            });
    } */ // <---- necesita POST!

    return <div className="container py-4">
        <h1 className="mb-4 nine text-dark text-uppercase">Analítica de datos</h1>

        <div className="py-5">
            <AnalyticsForm
                setResult={setResult}
                setSending={setSending}
            />
        </div>

        {result.ok && (
            <div>
                <h2>Resultado:</h2>

                {sending ? <Loading /> : <AnalyticsResult result={result.data} />}
            </div>
        )}
    </div>;
}

const root = document.getElementById("analytics");
if (root) {
    ReactDOM.render(<AnalyticsMain />, root);
}