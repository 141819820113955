import React, { useState, useEffect } from "react";
import Client from "./Client2";
import FormModal from "../../FormModal";
import ClientForm from "./ClientForm";

function ClientList(props) {
    const { clients, update, setIsLoading } = props;

    const [curId, setCurId] = useState(0);
    const [curDefaults, setCurDefaults] = useState({});
    const [selectedClient, setSelectedClient] = useState(0);
    const [modal, setModal] = useState(false);
    const [sending, setSending] = useState(false);
    const [filtered, setFiltered] = useState(clients);
    const [key, setKey] = useState("");
    const [species, setSpecies] = useState([]);

    useEffect(() => {
        axios("/api/species?all=true")
            .then((res) => {
                setSpecies(res.data);
            })
            .catch((err) => console.error(err));
    }, []);

    function toggle() {
        setModal(!modal);
    }

    function selectClient(id) {
        setCurId(id);
        const i = clients.findIndex((client) => client.id == id);
        const client = clients[i];
        const defaults = {
            user: client.user,
            name: client.name,
            email: client.email,
            phone: client.phone,
            password: "",
        };

        setCurDefaults(defaults);
        toggle();
    }

    function handleChange(newKey) {
        setKey(newKey);
        search(newKey);
    }

    function search(key) {
        const arr = clients.filter((user) =>
            user.name.toUpperCase().includes(key.toUpperCase())
        );

        setFiltered(arr);
    }

    filtered.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        } else if (a.name < b.name) {
            return -1;
        } else {
            return 0;
        }
    });

    return (
        <div id="client-accordion">
            <div className="bg-primary p-1 mb-4 rounded">
                <input
                    type="search"
                    className="form-control"
                    value={key}
                    placeholder="Buscar..."
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>

            {filtered.map((client) => (
                <Client
                    key={client.id}
                    client={client}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    species={species}
                    update={update}
                    selectClient={selectClient}
                    setIsLoading={setIsLoading}
                />
            ))}

            <FormModal
                title="Actualizar Cliente"
                modal={modal}
                toggle={toggle}
                submit="Guardar"
                isLoading={sending}
                form="updateClient"
            >
                <ClientForm
                    formId="updateClient"
                    setSending={setSending}
                    setIsLoading={setIsLoading}
                    toggle={toggle}
                    update={update}
                    edit={curId}
                    defaults={curDefaults}
                />
            </FormModal>
        </div>
    );
}

export default ClientList;
