import React, { useEffect, useRef } from "react";
import { useController } from "react-scroll-parallax";

function VideoBg(props) {
    const { parallaxController } = useController();
    useEffect(() => parallaxController.update());

    const videoRef = useRef(null);

    /* useEffect(() => {
        setInterval(() => {
            // console.log("refreshing video...");
            videoRef.current.load();
        }, 76000);
    }, [videoRef]); */

    const videoSource =
        "https://drive.google.com/uc?export=download&id=13LC2hFL5KYXyEue2y6QMVHHx04jprbVm";

    return (
        <div id="videoBg">
            <video
                ref={videoRef}
                autoPlay="autoplay"
                loop="loop"
                muted
                className="video"
                poster="/images/home/slider_poster.png"
                alt="reel de presentación de Paclife"
            >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div id="videoContent">{props.children}</div>
        </div>
    );
}

export default VideoBg;
