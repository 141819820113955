import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Loading from "../../Loading";
import FormModal from "../../FormModal";
import ClientForm from "./ClientForm";
import ClientList from "./ClientList";

function ClientsMain() {
    const [status, setStatus] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [modal, setModal] = useState(false);

    const [clients, setClients] = useState([]);
    useEffect(() => {
        axios
            .get("/api/clients")
            .then((res) => {
                setClients(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }, [status]);

    function toggle() {
        setModal(!modal);
    }

    function update() {
        // console.log("updating...");
        setStatus(status + 1);
    }

    return (
        <div className="container">
            <div className="row justify-content-center mb-5">
                <div className="col-md-8">
                    <h1 className="d-inline nine text-dark text-uppercase">
                        Gestor de clientes
                    </h1>

                    <FormModal
                        title="Nuevo Cliente"
                        modal={modal}
                        toggle={toggle}
                        submit="Guardar"
                        isLoading={sending}
                        form="newClient"
                        button
                    >
                        <ClientForm
                            formId="newClient"
                            setSending={setSending}
                            setIsLoading={setIsLoading}
                            toggle={toggle}
                            update={update}
                        />
                    </FormModal>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <ClientList
                            clients={clients}
                            update={update}
                            setIsLoading={setIsLoading}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

const root = document.getElementById("clients");
if (root) {
    ReactDOM.render(<ClientsMain />, root);
}
