import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEs from "../lang/es/translation.json";
import translationEn from "../lang/en/translation.json";
import translationZh from "../lang/zh/translation.json";

i18n.use(XHR)
    .use(LanguageDetector)
    .init({
        detection: {
            order: ["path", "navigator"],
            lookupFromPathIndex: 1,
        },

        debug: false,
        lng: "es",
        fallbackLng: "en", // use en if detected lng is not available

        nsSeparator: false,
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        resources: {
            es: {
                translations: translationEs,
            },
            en: {
                translations: translationEn,
            },
            zh: {
                translations: translationZh,
            },
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
    });

export default i18n;
