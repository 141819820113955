import React from "react";
import { ListGroupItem, Button } from "reactstrap";
import { Edit, XCircle } from "react-feather";
import SpeciesImg from "./SpeciesImg";

function Species(props) {
    const species = props.species;

    async function deleteSpecies() {
        await axios
            .delete("/api/species/" + species.id)
            .then(props.update())
            .catch((e) => console.error(e));
    }

    return (
        <ListGroupItem className="d-flex justify-content-between align-items-center">
            <p className="align-middle m-0 text-capitalize">{species.name}</p>
            <span>
                <SpeciesImg species={species} />
                <Button
                    color="link"
                    onClick={() => props.selectSpecies(species.id)}
                >
                    <Edit color="#ffc107" />
                </Button>
                <Button color="link" onClick={deleteSpecies}>
                    <XCircle color="#dc3545" />
                </Button>
            </span>
        </ListGroupItem>
    );
}

export default Species;
