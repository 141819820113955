import React, { useState } from "react";
import { Download, SkipBack, Upload, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Badge, Button, CustomInput, FormGroup, Label } from "reactstrap";
import * as yup from "yup";
import { CustomSelect } from "../../FormFields";
import DocumentCommentsList from "./DocumentCommentsList";
import DocumentImages from "./DocumentImages";

function DocumentFormTab(props) {
    const { document, docIndex, touched, updateDoc, rmDoc, edit } = props;

    const docTypes = [
        {
            id: "procesos",
            name: "procesos",
        },
        {
            id: "gases",
            name: "gases",
        },
    ];

    const [errors, setErrors] = useState({
        type: "",
        file: "",
        comments: "",
        images: "",
    });

    const schema = yup.object().shape({
        type: yup
            .string()
            .required("Especifique una categoría, por favor.")
            .oneOf(["procesos", "gases"], "Opción inválida."),
        file:
            edit && !document.updateFile
                ? yup.string().max(64)
                : yup
                    .mixed()
                    .required("Agregue un reporte, por favor.")
                    .test(
                        "fileSize",
                        "Archivo demasiado pesado. Peso máximo: 1 MB.",
                        (value) => value && value.size <= 1000 * 1024
                    )
                    .test(
                        "fileFormat",
                        "Formato no soportado. Debe ser pdf.",
                        (value) =>
                            value && ["application/pdf"].includes(value.type)
                    ),
        comments: yup.array().of(yup.string().max(1024)).ensure(),
        updateFile: yup.boolean(),
        images: yup.array().of(
            yup
                .mixed()
                .test(
                    "fileSize",
                    "Archivo demasiado pesado. Peso máximo: 200 KB.",
                    (value) => value && value.size <= 200 * 1024
                )
                .test(
                    "fileFormat",
                    "Formato no soportado. Debe ser jpg o png.",
                    (value) =>
                        value &&
                        ["image/jpeg", "image/png"].includes(value.type)
                )
        ).ensure(),
        rmImages: yup.array().of(yup.number().integer()).ensure(),
        action: yup
            .string()
            .oneOf(["create", "update", "delete"], "Opción inválida."),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        updateDoc(docIndex, field, value);

        if (field === "type") {
            if (value === "procesos") {
                updateDoc(docIndex, "comments", [
                    "Proceso de embalaje sin anomalías.",
                ]);
            } else if (value === "gases") {
                updateDoc(docIndex, "comments", [
                    "Niveles de gases y temperatura en rangos óptimos.",
                ]);
            }
        }

        if (touched) {
            validate(field, value);
        }
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function cancelUpdate(field) {
        changeValue(field, "");

        field = "update" + field.charAt(0).toUpperCase() + field.slice(1);
        changeValue(field, false);
    }

    function deleteDoc() {
        if (edit && document.id) {
            updateDoc(docIndex, "action", "delete");
        } else {
            rmDoc(docIndex);
        }
    }

    return (
        <div>
            <div className="mb-3 d-flex justify-content-end">
                {document.action === "delete" ? (
                    <div>
                        <Badge color="danger">Marcado para eliminar</Badge>

                        <Button
                            type="button"
                            color="link"
                            onClick={() =>
                                updateDoc(docIndex, "action", "update")
                            }
                            className="px-1 py-0"
                            data-tip
                            data-for="ttCancelDelete"
                        >
                            <SkipBack color="gray" size="18" />
                        </Button>

                        <ReactTooltip id="ttCancelDelete" effect="solid">
                            Cancelar eliminación
                        </ReactTooltip>
                    </div>
                ) : (
                    <div>
                        <XCircle
                            onClick={deleteDoc}
                            color="#cc0000"
                            className="pointer"
                            data-tip
                            data-for="ttEliminarDocumento"
                        />

                        <ReactTooltip id="ttEliminarDocumento" effect="solid">
                            <span>Eliminar documento</span>
                        </ReactTooltip>
                    </div>
                )}
            </div>

            <CustomSelect
                value={document.type || "0"}
                name="type"
                dName="categoría"
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-10"
                labelClass="col-md-2 col-form-label text-md-right text-capitalize"
                options={docTypes}
                touched={touched}
                error={errors.species}
                changeValue={changeValue}
            />

            <FormGroup className="row">
                <Label
                    for="file"
                    className="col-md-2 col-form-label text-md-right"
                >
                    Documento
                </Label>
                <div className="col-md-10">
                    {document.action !== "create" && !document.updateFile ? (
                        <div>
                            <a href={`/api/documents/${document.id}/download`}>
                                <Button
                                    type="button"
                                    color="success"
                                    className="mr-1"
                                >
                                    Descargar{" "}
                                    <Download color="white" size="18" />
                                </Button>
                            </a>

                            <Button
                                type="button"
                                color="warning"
                                onClick={() => changeValue("updateFile", true)}
                                className="mx-1"
                            >
                                Reemplazar <Upload color="#212529" size="18" />
                            </Button>
                        </div>
                    ) : (
                        <div className="d-flex">
                            <CustomInput
                                className={
                                    touched
                                        ? errors.file
                                            ? " is-invalid"
                                            : " is-valid"
                                        : ""
                                }
                                valid={touched && !errors.file}
                                invalid={touched && errors.file ? true : false}
                                type="file"
                                id="fileInput"
                                name="file"
                                onChange={(e) =>
                                    changeValue("file", e.target.files[0])
                                }
                            />

                            {document.action === "update" && (
                                <Button
                                    type="button"
                                    color="link"
                                    onClick={() => cancelUpdate("file")}
                                    className="pl-2 py-0"
                                    data-tip
                                    data-for="ttCancelUpload"
                                >
                                    <SkipBack color="gray" size="24" />

                                    <ReactTooltip
                                        id="ttCancelUpload"
                                        effect="solid"
                                    >
                                        Cancelar
                                    </ReactTooltip>
                                </Button>
                            )}
                        </div>
                    )}

                    <small className="text-danger">{errors.file}</small>
                </div>
            </FormGroup>

            <DocumentCommentsList
                comments={document.comments}
                changeValue={changeValue}
            />

            <DocumentImages
                oldImages={document.oldImages || []}
                images={document.images}
                rmImages={document.rmImages || []}
                error={errors.images}
                changeValue={changeValue}
            />
        </div>
    );
}

export default DocumentFormTab;
