import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner, ListGroup } from "reactstrap";
import Species from "./Species";
import EditSpecies from "./EditSpecies";

function SpeciesList() {
    const [species, setSpecies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState(0);
    const [curSpecies, setCurSpecies] = useState(0);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        axios
            .get("/api/species?all=true")
            .then((res) => {
                setSpecies(res.data);
                setIsLoading(false);
            })
            .catch((e) => console.error(e));
    }, [status]);

    function toggle() {
        setModal(!modal);
    }

    function update() {
        // console.log("updating...");
        setStatus(status + 1);
    }

    function selectSpecies(id) {
        setCurSpecies(species.findIndex((species) => species.id == id));
        toggle();
    }

    return isLoading ? (
        <div className="d-flex justify-content-center p-5">
            <Spinner color="light" />
        </div>
    ) : (
        <>
            <ListGroup>
                {species.map((species) => (
                    <Species
                        key={species.id}
                        species={species}
                        update={update}
                        selectSpecies={selectSpecies}
                    />
                ))}
            </ListGroup>

            <EditSpecies
                species={species[curSpecies]}
                isOpen={modal}
                toggle={toggle}
                update={update}
            />
        </>
    );
}

export default SpeciesList;
