import React from "react";
import { useTranslation } from "react-i18next";

function Feature(props) {
    const { img, title, text } = props;
    const { t, i18n } = useTranslation();

    function createMarkup() {
        return { __html: t(text) };
    }

    return (
        <div className="sustainable-r">
            <h3 className="three text-left mb-2">
                <img src={img} alt={title} />
                {t(title)}
            </h3>

            <p
                className="seven text-justify"
                dangerouslySetInnerHTML={createMarkup()}
            />
        </div>
    );
}

export default Feature;
