import React, { useState } from "react";
import { ChevronUp } from "react-feather";

function ToTop(props) {
    const [showScroll, setShowScroll] = useState(false);

    function checkScrollTop() {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    }

    window.addEventListener("scroll", checkScrollTop);

    return (
        <a href="#top">
            <ChevronUp
                className={showScroll ? "scrollTop" : "d-none"}
                size={80}
            />
        </a>
    );
}

export default ToTop;
