import React from "react";
import { useTranslation } from "react-i18next";
import Feature from "./Feature";

function Sustainable(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    const features = [
        {
            img: "/images/sustainable/1.png",
            title: "Reduce",
            text: "<p>Nuestra tecnología está diseñada para proteger y prolongar la vida útil de los alimentos <strong>evitando su desperdicio</strong>. ¿Sabías que en el mundo se desperdicia alrededor del 40% de los alimentos? ¡Evítalo con <strong>PacLife</strong>!</p>",
        },
        {
            img: "/images/sustainable/2.png",
            title: "Reutiliza",
            text: "<p>Todos nuestros productos están desarrollados para ser reutilizados, aportando <strong>sustentabilidad y ahorro</strong> a la cadena de producción de nuestros clientes.</p>",
        },
        {
            img: "/images/sustainable/3.png",
            title: "Recicla",
            text: '<p>Trabajamos solo con materiales nobles que permitan su reciclabilidad. Nuestra principal materia prima es el polietileno de baja densidad o <strong>LDPE</strong> (por sus siglas en inglés, <strong>Low Density Polyethylene</strong>).</p> <img src="/images/sustainable/ldpe.png" alt="LDPE logo" class="float-right">',
        },
    ];

    return (
        <section id={id} className={className + " text-center"}>
            <h2 className="two">
                {t("Piensa el futuro, preserva el presente")}
            </h2>

            <div
                id="sustainable-info"
                className="mt-2 mx-auto col-md-8 col-lg-6 col-xl-4"
            >
                <p className="seven">
                    {t(
                        "Nuestra tecnología está pensada para un uso sostenible de bajo impacto ambiental. Nos basamos en las tres leyes de la sustentabilidad"
                    )}
                    :
                </p>
            </div>

            <div id="three-r">
                {features.map((feat, i) => (
                    <Feature
                        key={i}
                        img={feat.img}
                        title={feat.title}
                        text={feat.text}
                    />
                ))}
            </div>
        </section>
    );
}

export default Sustainable;
