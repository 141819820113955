import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "reactstrap";
import NewSpecies from "./NewSpecies";
import SpeciesList from "./SpeciesList";

function MainSpecies() {
    const [status, setStatus] = useState(0);
    const [modal, setModal] = useState(false);

    function toggle() {
        setModal(!modal);
    }

    function update() {
        // console.log("updating...");
        setStatus(status + 1);
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center mb-3">
                    <div className="col-md-8">
                        <h1 className="d-inline">Species</h1>

                        <Button
                            outline
                            color="primary"
                            className="float-right"
                            onClick={toggle}
                        >
                            New Species
                        </Button>
                        <NewSpecies
                            isOpen={modal}
                            toggle={toggle}
                            update={update}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <SpeciesList />
                    </div>
                </div>
            </div>
        </>
    );
}

const root = document.getElementById("mainSpecies");
if (root) {
    ReactDOM.render(<MainSpecies />, root);
}
