import React from "react";
import { useSpring, animated } from "react-spring";

function ItemImg(props) {
    const { image } = props;

    const anim = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: { duration: 1000 },
        reset: true,
    });

    return (
        <animated.div style={anim} className="d-flex justify-content-center">
            <img
                key={image.path}
                src={image.path}
                className="img-fluid imgProd"
                alt={image.caption}
            />
        </animated.div>
    );
}

export default ItemImg;
