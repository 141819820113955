import React, { useState } from "react";
import axios from "axios";
import { Alert, ListGroup } from "reactstrap";
import Article from "./Article";

function ArticleList(props) {
    const { articles, setIsLoading, update } = props;
    const [deleting, setDeleting] = useState(0);
    const [error, setError] = useState("");

    function deleteArticle(id) {
        setDeleting(id);

        axios
            .delete("/api/articles/" + id)
            .then((res) => {
                console.log(res);
                setIsLoading(true);
                update();
                setDeleting(0);
            })
            .catch((err) => {
                console.error(err);
                setError(err.response.data.message);
                setDeleting(0);
            });
    }

    return (
        <>
            <Alert
                color="danger"
                isOpen={error ? true : false}
                toggle={() => setError("")}
                className="mb-2"
            >
                {error}
            </Alert>

            <ListGroup flush>
                {articles.map((article) => (
                    <Article
                        key={article.id}
                        article={article}
                        deleteArticle={deleteArticle}
                        deleting={deleting}
                    />
                ))}
            </ListGroup>
        </>
    );
}

export default ArticleList;
