import React, { useState } from "react";
import User from "./User";
import FormModal from "../../FormModal";
import NewUser from "./NewUser";
import EditUser from "./EditUser";

function UserList(props) {
    const { users, update, setIsLoading } = props;

    const [curId, setCurId] = useState(0);
    const [curDefaults, setCurDefaults] = useState({});
    const [modal, setModal] = useState(false);
    const [sending, setSending] = useState(false);

    function toggle() {
        setModal(!modal);
    }

    function selectUser(id) {
        setCurId(id);
        const i = users.findIndex((user) => user.id == id);
        const user = users[i];
        const defaults = {
            user: user.user,
            name: user.name,
            email: user.email,
            phone: user.phone,
            password: "",
            roles: user.roles.map((role) => role.id),
        };

        setCurDefaults(defaults);
        toggle();
    }

    users.sort((a, b) => {
        if (a.roles[0].id < b.roles[0].id) {
            return 1;
        } else if (a.roles[0].id > b.roles[0].id) {
            return -1;
        } else {
            return 0;
        }
    });

    return (
        <div className="accordion" id="accordionParent">
            {users.map((user) => (
                <User
                    key={user.id}
                    user={user}
                    update={update}
                    selectUser={selectUser}
                    setIsLoading={setIsLoading}
                />
            ))}

            <FormModal
                title="Actualizar Usuario"
                modal={modal}
                toggle={toggle}
                submit="Guardar"
                isLoading={sending}
                form="newUser"
            >
                <NewUser
                    setSending={setSending}
                    setIsLoading={setIsLoading}
                    toggle={toggle}
                    update={update}
                    edit={curId}
                    defaults={curDefaults}
                />
            </FormModal>
        </div>
    );
}

export default UserList;
