import React from "react";
import { useTranslation } from "react-i18next";

function NewsList(props) {
    const { news, curNews } = props;
    const { t, i18n } = useTranslation();

    function isActive(id) {
        if (curNews.id == id) {
            return "news-active";
        } else {
            return "news";
        }
    }

    return news.map((news) => (
        <a
            href={"/blog/" + news.slug}
            key={news.id}
            className="col-xl-5 three pointer"
        >
            <div className={"row " + isActive(news.id)}>
                <div className="col-4 col-xl">
                    <img
                        src={news.images[0].path}
                        alt={news.images[0].caption}
                        className="img-news-list bordeAzul"
                    />
                </div>

                <div className="three col-8 col-xl title-news-list text-left">
                    {t(news.title)}
                </div>
            </div>
        </a>
    ));
}

export default NewsList;
