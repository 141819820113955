import React from "react";

function Account(){
    const userData = (
        <>
            <div>
                <p>Usuario: Felipe</p>
            </div>
        </>
    )
    return(
        <>
            <div>
               <h2>
                   Account
                </h2>
                {userData} 
                <button className="btn btn-primary">Cambiar contraseña</button>
            </div>
        </>
    )
}

export default Account;