import React from "react";
import { Youtube } from "react-feather";

function Instructions() {
    return (
        <div className="bg-dark">
            <div className="container mt-5 py-5 d-flex flex-column align-items-center text-white">
                <h2 className="mb-3 align-self-start nine text-white text-uppercase">
                    Ayuda
                </h2>

                <div className="border border-white rounded p-4 col-lg-8">
                    <h3 className="one text-white text-uppercase text-center mb-3">
                        Insertar videos de YouTube
                    </h3>
                    <p>
                        En la barra de herramientas del editor para el cuerpo de
                        la noticia, presionar el botón{" "}
                        <Youtube color="white" size={15} />, e ingresar el
                        código del video. Este se puede extraer de la url del
                        video.
                    </p>
                    <p>
                        Ejemplo:{" "}
                        <code>
                            https://www.youtube.com/watch?v=
                            <strong>
                                <u>GH-UGtfGH8I</u>
                            </strong>
                        </code>
                        . La cadena de 11 caracteres que sigue a{" "}
                        <strong>watch?v=</strong> es el código.
                    </p>
                    <p>
                        Para borrar un video ya insertado, es necesario
                        presionar la tecla de borrado 3 veces en lugar de una.
                    </p>
                </div>

                <div className="border border-white rounded mt-4 p-4 col-lg-8">
                    <h3 className="one text-white text-uppercase text-center mb-3">
                        Alivianar una imagen
                    </h3>
                    <ol>
                        <li>
                            Ingresar a{" "}
                            <a
                                href="https://ezgif.com/optijpeg"
                                target="_blank"
                            >
                                ezgif
                            </a>
                            .
                        </li>
                        <li>
                            Hacer clic en <strong>Examinar...</strong> y
                            seleccionar la imagen en su equipo.
                        </li>
                        <li>
                            Hacer clic en el botón <strong>Upload!</strong>.
                        </li>
                        <li>
                            Hacer clic en el botón{" "}
                            <strong>Optimize image!</strong>.
                        </li>
                        <li>
                            En caso de que la fidelidad de la imagen disminuya
                            demasiado (factor dependiente del contenido de la
                            imagen), cambiar el valor de{" "}
                            <strong>image quality factor</strong> de 80 a 90, y
                            presionar <strong>Optimize image!</strong>{" "}
                            nuevamente.
                        </li>
                        <li>
                            Bajo la imagen optimizada, hacer clic en el botón{" "}
                            <strong>save</strong> y guardar la nueva imagen en
                            su equipo.
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default Instructions;
