import React from "react";
import { X } from "react-feather";
import { Button } from "reactstrap";

function AddedTag(props) {
    const { tag, index, rmTag } = props;

    return (
        <div className="mr-1 p-1 d-flex align-items-center rounded bg-secondary text-white">
            {tag}

            <Button
                color="link"
                className="ml-1 p-0"
                onClick={() => rmTag(index)}
            >
                <X color="white" size="14" />
            </Button>
        </div>
    );
}

export default AddedTag;
