import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown } from "react-feather";

function ItemList(props) {
    const { items, selectItem, selected, category } = props;
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="techListButton">
                <span className="four">{category}</span>
                <ChevronDown />
            </div>

            <div style={{ marginBottom: 2 + "rem" }}>
                <menu className="mt-3 itemList">
                    {items.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => selectItem(item.name)}
                            className={'productItem' + (selected == item.name ? '-active' : '')}
                        >
                            | {t(item.name)}
                        </li>
                    ))}
                </menu>
            </div>
        </div>
    );
}

export default ItemList;
