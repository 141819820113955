import React, { useState } from "react";
import { Edit, PlusCircle, Save, SkipBack, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import { Button, Col, Input, Row } from "reactstrap";

function DocumentCommentsList(props) {
    const { comments, changeValue } = props;

    const [editComment, setEditComment] = useState(-1);
    const [editState, setEditState] = useState(Array());

    function addCom() {
        let comBuff = comments;

        comBuff.push("");

        changeValue("comments", comBuff);
        setEditComment(comments.length - 1);
    }

    function editCom(comIndex) {
        let editBuff = Array.from(comments);

        setEditState(editBuff);
        setEditComment(comIndex);
    }

    function updateCom(comIndex, value) {
        let comBuff = comments;
        comBuff[comIndex] = value;

        changeValue("comments", comBuff);
    }

    function cancelCom() {
        setEditComment(-1);
        changeValue("comments", editState);
    }

    function rmCom(comIndex) {
        let comBuff = comments;

        comBuff.splice(comIndex, 1);

        changeValue("comments", comBuff);
    }

    return (
        <div className="mb-3 p-3 border rounded">
            <h4>Comentarios</h4>

            <ul>
                {comments.map((comment, i) => (
                    <li className="p-1" key={i}>
                        {editComment == i ? (
                            <div className="d-flex align-items-center">
                                <input
                                    className="w-100 flex-grow-1"
                                    type="text"
                                    name={"comment" + i}
                                    value={comment}
                                    placeholder="Comentario vacío"
                                    autoFocus
                                    onChange={(e) =>
                                        updateCom(i, e.target.value)
                                    }
                                    onBlur={() => setEditComment(-1)}
                                />

                                <div className="d-flex">
                                    <span>
                                        <Button
                                            type="button"
                                            color="link"
                                            // onClick={() => setEditComment(-1)}
                                            className="px-1 py-0"
                                            data-tip
                                            data-for="ttUpdateComment"
                                        >
                                            <Save color="#009955" size="24" />
                                        </Button>

                                        <ReactTooltip
                                            id="ttUpdateComment"
                                            effect="solid"
                                        >
                                            Guardar cambios
                                        </ReactTooltip>
                                    </span>

                                    <span className="pl-3">
                                        <Button
                                            type="button"
                                            color="link"
                                            onMouseDown={cancelCom}
                                            className="px-1 py-0"
                                            data-tip
                                            data-for="ttCancelComment"
                                        >
                                            <SkipBack color="gray" size="24" />
                                        </Button>

                                        <ReactTooltip
                                            id="ttCancelComment"
                                            effect="solid"
                                        >
                                            Descartar cambios
                                        </ReactTooltip>
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <span className="flex-grow-1">{comment}</span>

                                <div className="d-flex">
                                    <span>
                                        <Button
                                            type="button"
                                            color="link"
                                            onClick={() => editCom(i)}
                                            className="px-1 py-0"
                                            data-tip
                                            data-for="ttEditComment"
                                        >
                                            <Edit color="#ffc107" size="24" />
                                        </Button>

                                        <ReactTooltip
                                            id="ttEditComment"
                                            effect="solid"
                                        >
                                            Editar comentario
                                        </ReactTooltip>
                                    </span>

                                    <span className="pl-3">
                                        <Button
                                            type="button"
                                            color="link"
                                            onClick={() => rmCom(i)}
                                            className="px-1 py-0"
                                            data-tip
                                            data-for="ttDeleteComment"
                                        >
                                            <XCircle
                                                color="#dc3545"
                                                size="24"
                                            />
                                        </Button>

                                        <ReactTooltip
                                            id="ttDeleteComment"
                                            effect="solid"
                                        >
                                            Eliminar comentario
                                        </ReactTooltip>
                                    </span>
                                </div>
                            </div>
                        )}
                    </li>
                ))}
            </ul>

            <div className="center-xy pt-1">
                <Button
                    type="button"
                    color="link"
                    onClick={addCom}
                    className="px-1 py-0"
                    data-tip
                    data-for="ttCreateComment"
                >
                    <PlusCircle color="#009955" size="24" />
                </Button>

                <ReactTooltip id="ttCreateComment" effect="solid">
                    Nuevo comentario
                </ReactTooltip>
            </div>
        </div>
    );
}

export default DocumentCommentsList;
