import React, { useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik, Form } from "formik";
// import { BSInput, BSFile } from "../../Fields";
import * as Yup from "yup";
import axios from "axios";

function NewSpecies(props) {
    const { isOpen, toggle, update } = props;
    const formRef = useRef();

    function submitForm() {
        formRef.current.handleSubmit();
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Edit Species</ModalHeader>
            <ModalBody>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        name: "",
                        category: "",
                        imgPath: null,
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        name: Yup.string()
                            .max(50, "Must be 50 characters or less")
                            .required("Required"),
                        category: Yup.string()
                            .oneOf(["fruit", "vegetable"], "Invalid category")
                            .required("Required"),
                        imgPath: Yup.number(),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        axios
                            .post("/api/species/", values, {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            })
                            .then((res) => {
                                console.log(res);
                                setSubmitting(false);
                                update();
                                toggle();
                            })
                            .catch((err) => console.error(err));
                    }}
                >
                    {(formik) => (
                        <Form>
                            {/* <BSInput label="Name" name="name" type="text" />
                            <input name="imgPath" type="number" hidden />
                            <BSFile
                                label="Image"
                                name="img"
                                setFieldValue={formik.setFieldValue}
                            /> */}
                        </Form>
                    )}
                </Formik>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>{" "}
                <Button color="primary" onClick={submitForm}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default NewSpecies;
