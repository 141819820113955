import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChevronLeft, ChevronRight } from "react-feather";
import Loading from "../../Loading";

function AboutCarousel() {
    const { t, i18n } = useTranslation();

    const [milestones, setMilestones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get("/api/milestones")
            .then((res) => {
                setMilestones(res.data);
                setIsLoading(false);
            })
            .catch((err) => console.error(err));
    }, []);

    return isLoading ? (
        <Loading />
    ) : (
        <div
            id="milestones"
            className="carousel slide carousel-fade"
            data-ride="carousel"
        >
            <ol className="carousel-indicators">
                {milestones.map((milestone, index) => (
                    <li
                        key={milestone.id}
                        data-target="#milestones"
                        data-slide-to={index}
                        className={index === 0 ? "active" : ""}
                    ></li>
                ))}
            </ol>

            <div className="carousel-inner">
                {milestones.map((milestone, index) => (
                    <div
                        key={milestone.id}
                        className={
                            "carousel-item" + (index === 0 ? " active" : "")
                        }
                    >
                        <img
                            src={`/images/about/${i18n.language}/${milestone.name}.jpg`}
                            alt="milestone"
                            className="d-block w-100"
                        />
                    </div>
                ))}
            </div>

            <a
                className="carousel-control-prev"
                href="#milestones"
                role="button"
                data-slide="prev"
            >
                <ChevronLeft size={50} color="#009955" />
                <span className="sr-only">Previous</span>
            </a>

            <a
                className="carousel-control-next"
                href="#milestones"
                role="button"
                data-slide="next"
            >
                <ChevronRight size={50} color="#009955" />
                <span className="sr-only">Next</span>
            </a>
        </div>
    );
}

export default AboutCarousel;
