import React, { useState } from "react";
import axios from "axios";
import { Button, Card, CardBody, CardLink, Collapse } from "reactstrap";
import { Edit, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import ConfirmModal from "../../ConfirmModal";
import ClientContactList from "./ClientContactList";

function ClientPlant(props) {
    const { plant, otherPlants, clientId, species, update, activePlant, selectPlant, setEditPlant } = props;

    const [deleting, setDeleting] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);
    function toggleConfirmDelete() {
        setConfirmDelete(!confirmDelete);
    }

    function deletePlant() {
        setDeleting(true);

        axios
            .delete(`/api/plants/${plant.id}`)
            .then((res) => {
                // console.log(res);
                setDeleting(false);
                update();
            })
            .catch((err) => {
                console.error(err);
                alert(
                    "Error al eliminar planta. Por favor, asegúrese de que no existan reportes asociados a esta planta.\n\nDetalles técnicos:\n"
                    + err.response.data.message
                );
                setDeleting(false);
                toggleConfirmDelete();
            });
    }

    return (
        <Card className="bg-light mb-2">
            <CardBody className="p-2">
                <div className="d-flex justify-content-between px-2">
                    <h5 className="m-0">
                        <CardLink
                            className="pointer"
                            onClick={() => selectPlant(plant.id)}
                        >
                            {plant.name}
                        </CardLink>
                    </h5>

                    <div>
                        <span>
                            <Button
                                color="link"
                                onClick={() => setEditPlant(plant.id)}
                                className="px-1 py-0"
                                data-tip
                                data-for="ttEditPlant"
                            >
                                <Edit color="#ffc107" size="24" />
                            </Button>

                            <ReactTooltip id="ttEditPlant" effect="solid">
                                Editar planta
                            </ReactTooltip>
                        </span>

                        <span>
                            <Button
                                color="link"
                                onClick={toggleConfirmDelete}
                                className="px-1 py-0"
                                data-tip
                                data-for="ttDeletePlant"
                            >
                                <XCircle color="#dc3545" size="24" />
                            </Button>

                            <ReactTooltip id="ttDeletePlant" effect="solid">
                                Eliminar planta
                            </ReactTooltip>

                            <ConfirmModal
                                isOpen={confirmDelete}
                                toggle={toggleConfirmDelete}
                                action={deletePlant}
                                label="Eliminar"
                                loading={deleting}
                            >
                                ¿Realmente desea eliminar la planta{" "}
                                <strong>{plant.name}</strong>?
                            </ConfirmModal>
                        </span>
                    </div>
                </div>

                {activePlant === plant.id && (
                    <div className="pt-2">
                        <ClientContactList
                            species={species}
                            clientId={clientId}
                            plantId={plant.id}
                            otherPlants={otherPlants}
                        />
                    </div>
                )}
            </CardBody>
        </Card>
    );
}

export default ClientPlant;
