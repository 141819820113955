import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import * as yup from "yup";
import axios from "axios";
import { Button, Alert, Spinner, Card, CardBody, FormText } from "reactstrap";
import { ChevronLeft } from "react-feather";
import Help from "./Help";
import { Wysiwyg, ImgDrop, CustomDate, CustomInput } from "../../FormFields";
import Loading from "../../Loading";
import TagInput from "./TagInput";

function Editor(props) {
    const { edit, defaults } = props;

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);

    const fields = {
        title: "",
        body: "",
        image: "",
        thumbnail: "",
        description: "",
        caption: "",
        date: new Date(),
        tags: [],
    };

    useEffect(() => {
        // console.log("init");
        let emptyFields = {};
        Object.keys(fields).forEach((key) => (emptyFields[key] = ""));
        setErrors(emptyFields);

        function parseFile(blob, path) {
            const file = new File([blob], path, { type: blob.type });

            // console.log(file);
            return file;
        }

        if (edit) {
            let prepDefaults = { ...defaults };

            if (defaults.caption == "null") {
                prepDefaults.caption = "";
            }

            prepDefaults.date = new Date(defaults.date);

            const imgPath = defaults.image;
            const thmbPath = defaults.thumbnail;

            fetch(imgPath)
                .then((res) => res.blob())
                .then((blob) => {
                    prepDefaults.image = parseFile(blob, imgPath);
                    fetch(thmbPath)
                        .then((res) => res.blob())
                        .then((blob) => {
                            prepDefaults.thumbnail = parseFile(blob, thmbPath);
                            setValues(prepDefaults);
                            setLoading(false);
                        });
                });
        } else {
            setValues(fields);
            setLoading(false);
        }
    }, []);

    const schema = yup.object().shape({
        title: yup
            .string()
            .required("Ingrese un título, por favor.")
            .max(100, "Máximo 100 caracteres."),
        body: yup
            .string()
            .required(),
        image: yup
            .mixed()
            .test(
                "required",
                "Agregue una imagen, por favor.",
                (value) => value instanceof File
            )
            .test(
                "fileSize",
                "Archivo demasiado pesado. Peso máximo: 200 KB.",
                (value) => value && value.size <= 200 * 1024
            )
            .test(
                "fileFormat",
                "Formato no soportado. Debe ser jpg.",
                (value) => value && ["image/jpeg"].includes(value.type)
            ),
        thumbnail: yup
            .mixed()
            .test(
                "required",
                "Agregue una imagen, por favor.",
                (value) => value instanceof File
            )
            .test(
                "fileSize",
                "Archivo demasiado pesado. Peso máximo: 500 KB.",
                (value) => value && value.size <= 500 * 1024
            )
            .test(
                "fileFormat",
                "Formato no soportado. Debe ser jpg.",
                (value) => value && ["image/jpeg"].includes(value.type)
            ),
        description: yup
            .string()
            .required("Ingrese una descripción, por favor.")
            .min(100, "Mínimo 100 caracteres.")
            .max(300, "Máximo 300 caracteres."),
        caption: yup.string().nullable().max(100, "Máximo 100 caracteres."),
        date: yup.date().required("Ingrese una fecha, por favor."),
        tags: yup.array().nullable(),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setTouched(true);
        setSending(true);

        let sendData = new FormData();
        for (let [key, value] of Object.entries(values)) {
            if (Array.isArray(value)) {
                value.forEach((sub, index) => {
                    sendData.append(`${key}[${index}]`, sub);
                });
            } else {
                sendData.append(key, value);
            }
        }

        sendData.set("date", values.date.toISOString());

        schema
            .validate(values, { abortEarly: false })
            .then(() => {
                // console.log("sending...");
                axios({
                    method: "post",
                    url: "/api/articles" + (edit ? `/${edit}/update` : ""),
                    data: sendData,
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                })
                    .then((res) => {
                        console.log(res);
                        setSending(false);
                        setSuccess(true);
                    })
                    .catch((err) => {
                        console.error(err);
                        setServerError(err.response.data.message);
                        setSending(false);
                    });
            })
            .catch((err) => {
                console.log("validation error:");
                console.error(err);
                setErrMsgs(err.inner);
                setSending(false);
            });

        // console.log("data:");
        // for (var pair of sendData.entries()) {
        //     console.log(pair[0] + ": " + pair[1]);
        // }
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(inner) {
        let errBuff = {};
        let first = "";

        inner.forEach((validation) => {
            const field = validation.path;
            if (field.localeCompare(first) !== 0) {
                first = field;
                errBuff[field] = validation.errors[0];
            }
        });

        setServerError(
            "Existen errores de validación, por favor revise los datos ingresados"
        );
        setErrors({ ...errors, ...errBuff });
    }

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-between mb-5">
                    <div className="d-flex">
                        <a href="/admin/articles">
                            <ChevronLeft size={50} color="#3b6895" />
                        </a>
                        <h2 className="nine text-dark text-uppercase">
                            Editor de noticias
                        </h2>
                    </div>

                    <div>
                        <Button
                            type="submit"
                            color="primary"
                            className="btn-sqr"
                            form="newArticle"
                            outline
                        >
                            {sending ? (
                                <Spinner color="primary" size="sm" />
                            ) : (
                                "Guardar"
                            )}
                        </Button>
                    </div>
                </div>

                <Alert
                    color="success"
                    isOpen={success}
                    toggle={() => setSuccess(false)}
                    className="mb-3"
                >
                    Noticia guardada exitosamente.
                </Alert>
                <Alert
                    color="danger"
                    isOpen={serverError ? true : false}
                    toggle={() => setServerError("")}
                    className="mb-3"
                >
                    {serverError}
                </Alert>

                {loading ? (
                    <Loading />
                ) : (
                    <form id="newArticle" onSubmit={handleSubmit}>
                        <div className="mb-5 text-center">
                            <input
                                type="text"
                                name="title"
                                id="title"
                                className="edit-text three w-100"
                                placeholder="Título"
                                value={values.title}
                                onChange={(e) =>
                                    changeValue("title", e.target.value)
                                }
                                autoComplete="off"
                            />
                            {errors.title && (
                                <small className="text-danger">
                                    {errors.title}
                                </small>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-md-4 d-flex flex-column align-items-center six">
                                <ImgDrop
                                    image={values.image.name}
                                    name="image"
                                    update={changeValue}
                                    error={touched && errors.image}
                                    expHeight={338}
                                />
                                {errors.image && (
                                    <small className="text-danger">
                                        {errors.image}
                                    </small>
                                )}

                                <FormText className="align-self-start">
                                    450px ancho x 338px alto, 72dpi, 200KB máx.
                                </FormText>

                                <div className="mt-3 p-1 w-100 d-flex flex-column">
                                    <FormText>
                                        Descripicón corta para usuarios que no puedan ver la imagen
                                    </FormText>

                                    <input
                                        type="text"
                                        name="caption"
                                        id="caption"
                                        className="input-reset text-center w-100 p-1 border"
                                        placeholder="Descripción de imagen"
                                        value={values.caption || ""}
                                        onChange={(e) =>
                                            changeValue("caption", e.target.value)
                                        }
                                        autoComplete="off"
                                    />
                                    {errors.caption && (
                                        <small className="text-danger">
                                            {errors.caption}
                                        </small>
                                    )}

                                    <FormText className="align-self-end">
                                        {values.caption ? values.caption.length : 0}
                                        {' / 100 caracteres'}
                                    </FormText>
                                </div>
                            </div>

                            <div className="col-md-8 d-flex flex-column justify-content-between">
                                <Wysiwyg
                                    value={values.body}
                                    name="body"
                                    toolbarClass="floating-toolbar"
                                    editorClass="seven"
                                    placeholder="Cuerpo de la noticia"
                                    changeValue={changeValue}
                                    toolbarOnFocus={true}
                                />
                                {errors.body && (
                                    <small className="text-danger">
                                        {errors.body}
                                    </small>
                                )}

                                <div className="mt-2 text-right">
                                    <CustomDate
                                        value={values.date}
                                        name="date"
                                        dName="Fecha"
                                        isRequired={isRequired}
                                        labelClass="d-none"
                                        touched={touched}
                                        error={errors.date}
                                        changeValue={changeValue}
                                        inputClass="edit-text eleven text-right"
                                        placeholder="Fecha"
                                        time
                                    />
                                </div>
                            </div>
                        </div>

                        <hr style={{ margin: "5rem 0" }} />

                        <div>
                            <h2 className="one text-dark text-uppercase text-center mb-3">
                                Etiquetas
                            </h2>

                            <TagInput
                                tagArray={values.tags}
                                update={changeValue}
                            />

                            <small className="text-muted">
                                Agregue etiquetas presionando{" "}
                                <strong>Enter</strong> o <strong>coma</strong>
                            </small>
                        </div>

                        <hr style={{ margin: "5rem 0" }} />

                        <div id="linkedin" className="mt-5">
                            <h2 className="one text-dark text-uppercase text-center mb-3">
                                Miniatura LinkedIn
                            </h2>
                        </div>
                        <div className="center-xy">
                            <Card className="col-lg-8 mb-2">
                                <CardBody>
                                    <ImgDrop
                                        image={values.thumbnail.name}
                                        name="thumbnail"
                                        update={changeValue}
                                        error={touched && errors.thumbnail}
                                        expHeight={453.53}
                                    />
                                    {errors.thumbnail && (
                                        <small className="text-danger">
                                            {errors.thumbnail}
                                        </small>
                                    )}

                                    <FormText>
                                        1200px ancho x 627px alto, 72dpi, 500KB máx.
                                    </FormText>

                                    <CustomInput
                                        value={values.description || ""}
                                        type="textarea"
                                        name="description"
                                        outerDiv="mt-3"
                                        placeholder="Descripción"
                                        isRequired={isRequired}
                                        labelClass="d-none"
                                        touched={touched}
                                        error={errors.description}
                                        changeValue={changeValue}
                                        charCount={true}
                                        limit="300"
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </form>
                )}
            </div>

            <Help />
        </>
    );
}

const root = document.getElementById("editor");
if (root) {
    const props = Object.assign({}, root.dataset);

    if ("edit" in props) {
        props.defaults = JSON.parse(props.defaults);
    }

    ReactDOM.render(<Editor {...props} />, root);
}
