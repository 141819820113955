import React from "react";
import { useTranslation } from "react-i18next";

function Title() {
    const { t, i18n } = useTranslation();

    return (
        <h1 className="bg-primary text-white four text-center m-0 p-2">
            {"Paclife | " + t("Envases de Atmósfera Modificada")}
        </h1>
    );
}

export default Title;
