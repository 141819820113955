import React from "react";

function ItemImg(props) {
    const { image } = props;

    return (
        <div className="d-flex justify-content-center">
            <img
                key={image.path}
                src={image.path}
                className="img-fluid imgProd"
                alt={image.caption}
            />
        </div>
    );
}

export default ItemImg;
