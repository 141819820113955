import React from "react";
import { useTranslation } from "react-i18next";

function CurArticle(props) {
    const { article } = props;
    const { t, i18n } = useTranslation();

    function createMarkup() {
        return { __html: t(article.body) };
    }

    return (
        <article className="mb-4 pt-4">
            <h3 className="article-title">{t(article.title)}</h3>

            <div className="row">
                <div className="col-md-4 d-flex flex-column align-items-center six">
                    <img
                        src={article.images[0].path}
                        alt={article.images[0].caption}
                        className="w-100 h-auto mb-3"
                    />

                    <p>{t(article.images[0].caption)}</p>
                </div>

                <div className="col-md-8 d-flex flex-column justify-content-between">
                    <div
                        dangerouslySetInnerHTML={createMarkup()}
                        className="seven"
                    />

                    <time
                        className="eleven align-self-end mt-2"
                        dateTime={article.date}
                    >
                        {new Date(article.date).toLocaleDateString()}
                    </time>
                </div>
            </div>
        </article>
    );
}

export default CurArticle;
