import React from "react";
import { X } from "react-feather";

function TagBox(props) {
    const { curArticle, curTag, setCurTag } = props;

    return (
        <div className="tag-box">
            {curArticle.tags.map((tag, i) => (
                <div key={i}>
                    {i > 0 && <span>/</span>}

                    <button
                        className={
                            "tag-btn" + (tag === curTag ? "-active" : "")
                        }
                        onClick={() => setCurTag(tag)}
                    >
                        #{tag}
                    </button>
                </div>
            ))}

            {curTag && (
                <button className="tag-btn" onClick={() => setCurTag(null)}>
                    <X size={18} />
                </button>
            )}
        </div>
    );
}

export default TagBox;
