import React, { useState } from "react";
import { Button } from "reactstrap";
import { Edit, XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import ConfirmModal from "../../ConfirmModal";
import ClientPlantList from "./ClientPlantList";

function Client(props) {
    const {
        client,
        selectedClient,
        setSelectedClient,
        species,
        update,
        selectClient,
        setIsLoading,
    } = props;

    const [deleting, setDeleting] = useState(0);

    const [confirmDelete, setConfirmDelete] = useState(false);
    function toggleConfirmDelete() {
        setConfirmDelete(!confirmDelete);
    }

    function deleteClient() {
        setDeleting(client.id);

        axios
            .delete("/api/clients/" + client.id)
            .then((res) => {
                console.log(res);
                setDeleting(0);
                update();
                setIsLoading(true);
            })
            .catch((err) => {
                console.error(err);
                alert(err.response.data);
                setDeleting(0);
            });
    }

    function toggleClient(id) {
        if (id === selectedClient) {
            setSelectedClient(0);
        } else {
            setSelectedClient(id);
        }
    }

    return (
        <div className="card mb-2">
            <div className="card-body p-2">
                <div
                    className="d-flex align-items-center justify-content-between px-2"
                    id={"client-heading" + client.id}
                >
                    <button
                        className="btn btn-link"
                        onClick={() => toggleClient(client.id)}
                    >
                        <h4 className="m-0">{client.name}</h4>
                    </button>

                    <div className="col-sm-3 d-flex align-items-end justify-content-end">
                        <div>
                            <Button
                                id="editButton"
                                color="link"
                                onClick={() => selectClient(client.id)}
                                data-tip
                                data-for="ttEditarCliente"
                            >
                                <Edit color="#ffc107" />
                            </Button>

                            <ReactTooltip id="ttEditarCliente" effect="solid">
                                <span>Editar cliente</span>
                            </ReactTooltip>
                        </div>

                        <div>
                            <Button
                                id="deleteButton"
                                color="link"
                                onClick={toggleConfirmDelete}
                                data-tip
                                data-for="ttEliminarCliente"
                            >
                                <XCircle color="#dc3545" />
                            </Button>

                            <ReactTooltip id="ttEliminarCliente" effect="solid">
                                <span>Eliminar cliente</span>
                            </ReactTooltip>

                            <ConfirmModal
                                isOpen={confirmDelete}
                                toggle={toggleConfirmDelete}
                                action={deleteClient}
                                label="Eliminar"
                                loading={deleting == client.id}
                            >
                                ¿Realmente desea eliminar el cliente{" "}
                                <strong>{client.name}</strong>?
                            </ConfirmModal>
                        </div>
                    </div>
                </div>

                {client.id === selectedClient && (
                    <div className="p-2">
                        <ClientPlantList
                            species={species}
                            clientId={client.id}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Client;
