import React, { useState } from "react";
import { ChevronRight } from "react-feather";
import { ChevronLeft } from "react-feather";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Alert } from "reactstrap";
import { Button } from "reactstrap";
import Loading from "../../Loading";

const errorAlert = <Alert color="danger">Error al cargar documento</Alert>

function DocumentPreview(props) {
    const { url } = props;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    return <>
        <Document
            file={url}
            loading={<Loading />}
            onLoadSuccess={onDocumentLoadSuccess}
            error={errorAlert}
        >
            <Page pageNumber={pageNumber} width={1046} />
        </Document>

        {numPages > 1 && (
            <div className="center-xy">
                <Button
                    color="link"
                    disabled={pageNumber <= 1}
                    onClick={() => changePage(-1)}
                >
                    <ChevronLeft />
                </Button>

                <small className="mx-1 text-muted">
                    {pageNumber} / {numPages}
                </small>

                <Button
                    color="link"
                    disabled={pageNumber >= numPages}
                    onClick={() => changePage(1)}
                >
                    <ChevronRight />
                </Button>
            </div>
        )}
    </>;
}

export default DocumentPreview;
