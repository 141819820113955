import React from "react";

function ArticleCard(props) {
    const { article, isActive } = props;

    return (
        <div className="col">
            <a
                href={"/blog/" + article.slug}
                className={"article-card" + (isActive ? "-active" : "")}
            >
                <img
                    src={article.images[0].path}
                    alt={article.images[0].caption}
                    // height="150"
                    // width="199"
                />

                <div>{article.title}</div>
            </a>
        </div>
    );
}

export default ArticleCard;
