import React from "react";
import { ListGroupItem, Button, Spinner } from "reactstrap";
import { Edit, XCircle } from "react-feather";

function Article(props) {
    const { article, deleteArticle, deleting } = props;

    return (
        <ListGroupItem className="d-flex justify-content-between align-items-center">
            <div className="article-item">
                <img className="article-img" src={article.images[0].path} />

                <div className="article-info">
                    <p className="text-capitalize">{article.title}</p>

                    <p className="font-weight-light">
                        {new Date(article.date).toLocaleDateString()}
                    </p>

                    <a href={`/blog/${article.slug}`}>
                        {`https://paclife.tech/blog/${article.slug}`}
                    </a>

                    <div
                        className="mt-2 d-flex flex-wrap"
                        style={{ fontSize: "smaller" }}
                    >
                        {article.tags.map((tag, i) => (
                            <div key={i}>
                                <span>#{tag}</span>

                                {i < article.tags.length - 1 && (
                                    <pre className="d-inline">, </pre>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <span style={{ flexShrink: 0 }}>
                <a href={`/api/articles/${article.id}/edit`}>
                    <Edit color="#c9d300" />
                </a>

                <Button color="link" onClick={() => deleteArticle(article.id)}>
                    {deleting == article.id ? (
                        <Spinner color="danger" size="sm" />
                    ) : (
                        <XCircle color="#cc0000" />
                    )}
                </Button>
            </span>
        </ListGroupItem>
    );
}

export default Article;
