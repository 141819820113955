import React, { useState } from "react";
import ReactDOM from "react-dom";
import Content from "./Content";

function Dashboard(props) {
    const [route, setRoute] = useState("das");

    function router(w) {
        setRoute(w);
    }

    return (
        <Content
            route={route}
            router={router}
            isClient={Number(props.isClient)}
        />
    );
}

const root = document.getElementById("dashboard");
if (root) {
    const props = Object.assign({}, root.dataset);
    ReactDOM.render(<Dashboard {...props} />, root);
}
