import React, { useState, useEffect } from "react";
import { Form, Input, CustomInput, Button } from "reactstrap";

function ReportFilters(props) {
    const {
        isClient,
        clientSelected,
        filters,
        setFilters,
        resetFilters,
        setFilterQuery,
        species,
        setCurSpecies,
        plants,
        seasons,
    } = props;

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setFilters({ ...filters, [field]: value });
    }

    function convertToUTC(date) {
        const num = date.split("-");
        const dateTime = new Date(num[0], num[1] - 1, num[2]);

        return dateTime.toISOString();
    }

    function handleSubmit(e) {
        e.preventDefault();

        let query = "";

        if (filters.draft >= 0) {
            query += `&draft=${filters.draft}`;
        }
        if (filters.species) {
            query += `&species=${filters.species}`;
        }
        if (filters.plant) {
            query += `&plant=${filters.plant}`;
        }
        if (filters.season) {
            query += `&season=${filters.season}`;
        }
        if (filters.date) {
            query += `&date=${convertToUTC(filters.date)}`;
        }

        setFilterQuery(query);
    }

    function selectSpecies(e) {
        changeValue("species", e.target.value);

        if (isClient) {
            const selectedSpecies = species.find(
                (species) => species.id == e.target.value
            );
            setCurSpecies(selectedSpecies);
        }
    }

    return (
        <div className="border border-gray bg-light p-2 mb-3">
            <h4>Filtros</h4>

            <Form inline id="filtersForm" onSubmit={handleSubmit}>
                <CustomInput
                    className={
                        "mr-2 mb-2" + (isClient ? " client-species-select" : "")
                    }
                    type="select"
                    id="species"
                    name="species"
                    value={filters.species}
                    onChange={selectSpecies}
                >
                    <option value={0} disabled>
                        Especie
                    </option>

                    {species.map((species) => (
                        <option key={species.id} value={species.id}>
                            {species.name}
                        </option>
                    ))}
                </CustomInput>

                {clientSelected && (
                    <CustomInput
                        className="mr-2 mb-2"
                        type="select"
                        id="plant"
                        name="plant"
                        value={filters.plant}
                        onChange={(e) => changeValue("plant", e.target.value)}
                    >
                        <option value={0} disabled>
                            Planta
                        </option>

                        {plants.map((plant) => (
                            <option key={plant.id} value={plant.id}>
                                {plant.name}
                            </option>
                        ))}
                    </CustomInput>
                )}

                <CustomInput
                    className="mr-2 mb-2"
                    type="select"
                    id="season"
                    name="season"
                    value={filters.season}
                    onChange={(e) => changeValue("season", e.target.value)}
                >
                    <option value={0} disabled>
                        Temporada
                    </option>

                    {seasons.map((season) => (
                        <option key={season.id} value={season.id}>
                            {season.name}
                        </option>
                    ))}
                </CustomInput>

                <CustomInput
                    className="mr-2 mb-2"
                    type="select"
                    id="draft"
                    name="draft"
                    value={filters.draft}
                    onChange={(e) => changeValue("draft", e.target.value)}
                >
                    <option value={-1} disabled>
                        Estado
                    </option>
                    <option value={1}>Borrador</option>
                    <option value={0}>Enviado</option>
                </CustomInput>

                <Input
                    className="mr-2 mb-2"
                    type="date"
                    name="date"
                    value={filters.date}
                    onChange={(e) => changeValue("date", e.target.value)}
                />

                <Button
                    color="secondary"
                    className="mr-2 mb-2"
                    onClick={resetFilters}
                >
                    Vaciar filtros
                </Button>

                <Button type="submit" color="primary" className="mb-2">
                    Aplicar
                </Button>
            </Form>
        </div>
    );
}

export default ReportFilters;
