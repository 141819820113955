import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";
import * as yup from "yup";
import axios from "axios";
import { Form, Button, Spinner, Alert } from "reactstrap";
import { CustomInput } from "../FormFields";

function Login() {
    const { t, i18n } = useTranslation();

    const [values, setValues] = useState({});
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const fields = { user: "", password: "" };

    useEffect(() => {
        // console.log("init");

        setValues(fields);
        setErrors(fields);
    }, []);

    const schema = yup.object().shape({
        user: yup.string().required(),
        password: yup.string().required(),
    });

    function isRequired(name) {
        return schema.describe().fields[name].tests[0].name === "required";
    }

    function validate(field, value) {
        // console.log("validating " + field);

        yup.reach(schema, field)
            .validate(value)
            .then(() => setErrors({ ...errors, [field]: "" }))
            .catch((err) => setErrors({ ...errors, [field]: err.errors[0] }));
    }

    function changeValue(field, value) {
        // console.log("updating value of " + field + " to:");
        // console.log(value);
        setValues({ ...values, [field]: value });

        if (touched) {
            validate(field, value);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();

        setLoading(true);
        setTouched(true);
        setFailed(false);

        const data = { ...values, remember: true };

        schema
            .validate(values, { abortEarly: false })
            .then(() =>
                axios
                    .get("/sanctum/csrf-cookie")
                    .then(() => {
                        axios
                            .post("/login", data)
                            .then((res) => window.location.replace(res.data))
                            .catch((err) => {
                                console.error(err);
                                setFailed(true);
                                setLoading(false);
                            });
                    })
                    .catch((err) => console.error(err))
            )
            .catch((err) => {
                console.error(err);
                setErrMsgs(err.errors);
                setLoading(false);
            });

        // console.log("data:");
        // console.log(data);
        // console.log("values:");
        // console.log(values);
        // console.log("touched:");
        // console.log(touched);
        // console.log("errors:");
        // console.log(errors);
    }

    function setErrMsgs(msgs) {
        let errBuff = {};

        msgs.forEach((msg) => {
            const field = msg.substring(0, msg.indexOf(" "));
            errBuff[field] = msg;
        });

        setErrors({ ...errors, ...errBuff });
    }

    return (
        <Form onSubmit={handleSubmit}>
            <CustomInput
                value={values.user || ""}
                type="text"
                name="user"
                dName="Usuario/Correo"
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-6"
                labelClass="col-md-4 col-form-label text-md-right text-capitalize"
                placeholder="Ingrese su usuario o correo electrónico"
                autoFocus
                touched={touched}
                error={errors.user}
                changeValue={changeValue}
            />

            <CustomInput
                value={values.password || ""}
                type="password"
                name="password"
                dName="contraseña"
                isRequired={isRequired}
                outerDiv="row"
                innerDiv="col-md-6"
                labelClass="col-md-4 col-form-label text-md-right text-capitalize"
                placeholder="Ingrese su contraseña"
                autocomplete="current-password"
                touched={touched}
                error={errors.password}
                changeValue={changeValue}
            />

            <Alert
                color="danger"
                isOpen={failed}
                toggle={() => setFailed(false)}
                className="mt-3"
            >
                {t("Estas credenciales no coinciden con nuestros registros.")}
            </Alert>

            <div className="d-flex flex-column pt-2">
                <p className="text-danger text-right">
                    *{t("Campos obligatorios")}
                </p>

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-end">
                    <a href="/password/reset" className="pb-3 pb-sm-0">
                        {t("Olvidé mi contraseña")}
                    </a>

                    <Button color="primary">
                        {loading ? (
                            <Spinner color="white" size="sm" />
                        ) : (
                            t("Ingresar")
                        )}
                    </Button>
                </div>
            </div>
        </Form>
    );
}

const root = document.getElementById("login");
if (root) {
    ReactDOM.render(<Login />, root);
}
