import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ChevronRight } from "react-feather";
import { ChevronLeft } from "react-feather";
import { Document, Outline, Page } from 'react-pdf';
import { Button } from "reactstrap";
import { Alert } from "reactstrap";
import Loading from "./Loading";

const errorAlert = <Alert color="danger">Error al cargar documento</Alert>;

const index = [
    {
        esp: "ÍNDICE",
        eng: "INDEX",
        page: 4,
    },
    {
        esp: "QUIÉNES SOMOS",
        eng: "ABOUT US",
        page: 5,
    },
    {
        esp: "PROCESOS",
        eng: "POST-HARVEST PROCESSES",
        page: 6,
    },
    {
        esp: "ARÁNDANOS",
        eng: "BLUEBERRIES",
        page: 8,
    },
    {
        esp: "BAYAS",
        eng: "BERRIES",
        page: 12,
    },
    {
        esp: "CAROZOS",
        eng: "STONE FRUITS",
        page: 16,
    },
    {
        esp: "CEREZAS",
        eng: "CHERRIES",
        page: 20,
    },
    {
        esp: "ESPÁRRAGOS",
        eng: "ASPARAGUS",
        page: 24,
    },
    {
        esp: "FLORES",
        eng: "FLOWERS",
        page: 28,
    },
    {
        esp: "GRANADAS",
        eng: "POMEGRANATES",
        page: 32,
    },
    {
        esp: "GULUPAS",
        eng: "PASSION FRUITS",
        page: 36,
    },
    {
        esp: "KIWIS",
        eng: "KIWIS",
        page: 40,
    },
    {
        esp: "PERAS & MANZANAS",
        eng: "APPLES & PEARS",
        page: 44,
    },
    {
        esp: "PALTAS / AGUACATES",
        eng: "AVOCADOS",
        page: 48,
    },
    {
        esp: "UVAS DE MESA",
        eng: "TABLE GRAPES",
        page: 52,
    },
    {
        esp: "NUEVOS DESARROLLOS",
        eng: "NEW DEVELOPMENTS",
        page: 56,
    },
];

function Catalog() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageWidth, setPageWidth] = useState(0);

    const headingRef = useRef(null);

    function onDocumentLoadSuccess({ numPages }) {
        const resizeObserver = new ResizeObserver(adjustWidth);
        resizeObserver.observe(headingRef.current);

        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function onItemClick({ pageNumber: itemPageNumber }) {
        setPageNumber(itemPageNumber);
    }

    function adjustWidth() {
        setPageWidth(headingRef.current.clientWidth)
    }

    return (
        <div className="p-2 min-vh-100 center-xy bg-dark">
            <Document
                file="/storage/docs/catalogo_web.pdf"
                loading={<Loading />}
                onLoadSuccess={onDocumentLoadSuccess}
                error={errorAlert}
                onItemClick={onItemClick}
                className="container"
            >
                <h1 ref={headingRef} className="nine">Catálogo</h1>

                <Page
                    pageNumber={pageNumber}
                    loading={<Loading />}
                    renderAnnotationLayer={false}
                    width={pageWidth}
                />

                <div className="center-xy m-2 mb-5">
                    <Button
                        color="secondary"
                        disabled={pageNumber <= 1}
                        onClick={() => changePage(-1)}
                    >
                        <ChevronLeft />
                    </Button>

                    <div className="mx-4 text-white">
                        {pageNumber} / {numPages}
                    </div>

                    <Button
                        color="secondary"
                        disabled={pageNumber >= numPages}
                        onClick={() => changePage(1)}
                    >
                        <ChevronRight />
                    </Button>
                </div>

                <h2 className="nine">Tabla de contenidos</h2>

                <ul className="index rounded">
                    {index.map((chapter, i) => (
                        <li
                            key={i}
                            onClick={() => setPageNumber(chapter.page)}
                            className="index-item"
                        >
                            <span className="chapter-esp">{chapter.esp}</span>
                            <span className="chapter-eng">{chapter.eng}</span>
                            <span className="chapter-page">{chapter.page}</span>
                        </li>
                    ))}
                </ul>
            </Document>
        </div>
    );
}

const root = document.getElementById("catalog");
if (root) {
    ReactDOM.render(<Catalog />, root);
}