import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import ContactForm from "./ContactForm";

function Contact(props) {
    const { id, className } = props;
    const { t, i18n } = useTranslation();

    const [sent, setSent] = useState(false);

    return (
        <section id={id} className={className}>
            <h2 className="two text-center">{t("Contacto")}</h2>

            {sent ? (
                <Alert color="success" className="mx-auto col-md-10">
                    {t(
                        "¡Mensaje enviado! Gracias por escribirnos, nos comunicaremos con usted a la brevedad."
                    )}
                </Alert>
            ) : (
                <div className="border border-dark">
                    <ContactForm setSent={setSent} />
                </div>
            )}
        </section>
    );
}

export default Contact;
